.pageTitle.pageTitle-error {
	text-align: center;
	@include respond(840px) {
		padding-top: 120px;
		padding-bottom: 60px;
		
		h1, p {
			color: #fff;
		}
	}
	
	.pageTitle-spacer {
		background-image: url(../webp/404.webp);
	}
	
	.hero-wrap .container {
		max-width: 635px;
		p {
			font-size: 18px;
			line-height: 26px;
			@include respond(840px) {
				font-size: 26px;
				line-height: 32px;
			}
		}
		.error-attribution {
			display: block;
			line-height: 26px;
			font-size: 16px;
			margin: 8px 0 0 0;
			@include respond(840px) {
				margin: 16px 0 0 0;
				font-size: 18px;
				line-height: 32px;
			}
		}
	}
}


.errorSubhero {
	text-align: center;
	padding: 80px 0;
	@include respond(840px) {
		background-size: auto;
		background-repeat: no-repeat;
		background-position: left top;
		background-image: url(../webp/top-left-blur.webp);	
	}
	@include respond($bp-padding) {
		padding: 120px 0 80px 0;
	}
	
	
	.container {
		max-width: 640px;
		
		.errorSubhero-title {
			font-family: $font-accent;
			font-weight: $medium;
			letter-spacing: -0.02em;
			font-size: 20px;
			line-height: 34px;									
			color: $color-blue;
			@include respond($bp-padding) {
				font-size: 32px;
				line-height: 48px;							
			}
		}
		
		p {
			font-family: $font-accent;
			font-weight: $medium;
			letter-spacing: -0.02em;
			font-size: 16px;
			line-height: 24px;									
			@include respond($bp-padding) {
				font-size: 20px;
				line-height: 32px;							
			}
		}
	}
	
}

.errorGrid {
	.container {
		display: flex;
		align-items: stretch;
		flex-wrap: wrap;
		justify-content: center;
				
		gap: 32px;
		max-width: 1356px;
	}
}

.errorGrid-box {
	background: #fff;
	box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
	padding: 48px 28px 32px 28px; 
	text-align: center;
	transition: box-shadow 0.4s ease;
	text-decoration: none;
	&:hover {
		box-shadow: 0px 0px 40px rgba(0, 127, 173, 0.2);
	}
	flex: 1 1 calc(25% - 24px);
	min-width: 200px;
	max-width: calc(25% - 24px);
}

.errorGrid-box-title {
	font-family: $font-accent;
	font-weight: 500;
	text-align: center;
	letter-spacing: -0.02em;
	margin: 8px 0 16px 0;
	font-size: 18px;
	line-height: 24px;
	color: $color-blue;
	@include respond($bp-padding) {
		font-size: 22px;
		line-height: 32px;
	}
}

.errorGrid-box-text {
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: $color-body;
	@include respond($bp-padding) {
		font-size: 18px;
		line-height: 32px;
	}
}


.errorSearch {
	padding: 80px 0;
	background-image: url(../svg/gridEntries-bg.svg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: bottom;
	@include respond($bp-padding) {
		padding: 120px 0;
	}
	
	.errorSearch-title {
		font-weight: 500;
		font-size: 22px;
		line-height: 32px;
		text-align: center;
		max-width: 622px;
		margin: 0 auto 24px auto;
		color: $color-dark;
		
		@include respond($bp-padding) {
			font-size: 24px;
			line-height: 32px;
		}
	}
	
	form {
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
	}
}