$bp-upnext: 600px;

.upNext{
	
	max-width: 1368px;
	width: 100%;	
	padding: 0 0 0 24px;
	margin: 40px auto 0 auto;
	@include respond($bp-upnext) {
		padding: 0 24px;
	}
	@include respond(860px) {
		margin: 0;
		position: absolute;
		top: 100%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: $z_index-level1 + 1;
	}
}

.upNext-inner {
	padding: 8px 24px;
	background: #fff;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 32px;
	box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);	
	@include respond(1200px) {
		padding: 8px 90px;	
	}
	@include respond($bp-padding, "down") {
		display: grid;
		grid-template-columns: 1fr 110px;
		gap: 8px 24px;
	}
	@include respond($bp-upnext, "down") {
		display: flex;
		overflow: auto;	
		gap: 0 48px;
		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
		&::-webkit-scrollbar {
			display: none;
		}	
	}
}

.upNext-block {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 16px;
	* {
		white-space: nowrap;
	}
	@include respond($bp-padding, "down") {
		grid-column: 1;	
	}
}

.upNext-cta {
	margin: 0 0 0 auto;
	
	@include respond($bp-padding, "down") {
		grid-column: 2;
		grid-row: 1 / span 2;	
	}
}

.upNext-block-label {
	text-transform: uppercase;
	font-size: 1.4rem;
	line-height: calc(21/14);
	font-weight: $bold;
	color: $color-teal;
	@include respond($bp-upnext) {
		font-size: 1.6rem;
		line-height: calc(26/16);	
	}
	
}
.upNext-block img {
	border-radius: 50%;
	width: 24px;
	height: 24px;
	@include respond($bp-upnext) {
		width: 48px;
		height: 48px;
	}
}
.upNext-block-text {
	font-size: 1.2rem;
	line-height: calc(18/12);
	@include respond($bp-upnext) {
		white-space: normal;
		font-size: 1.4rem;
		line-height: calc(21/14);
	}
}