// Animations variables
$aos-distance: 50px !default;

/**
 * Fade animations:
 * fade
 * fade-up, fade-down, fade-left, fade-right
 * fade-up-right, fade-up-left, fade-down-right, fade-down-left
 */

@mixin animation-factory($prefix: '') {
	.#{$prefix}animation-fade-up,
	.#{$prefix}animation-fade-left,
	.#{$prefix}animation-fade-right,
	.#{$prefix}animation-fade-up-right,
	.#{$prefix}animation-fade-up-left,
	.#{$prefix}animation-fade-down,
	.#{$prefix}animation-fade-down-right,
	.#{$prefix}animation-fade-down-left,
	.#{$prefix}animation-fade-in,
	.#{$prefix}animation-scale-in {
		transition-duration: 0.75s; // 1000ms;
		opacity: 0;
		transition-property: opacity, transform;

		&.inviewport {
			opacity: 1;
			transform: translate3d(0, 0, 0) scale(1);
		}

		&.animation-delay-0 {
			transition-delay: 0s;
		}

		&.animation-delay-1 {
			transition-delay: 0.25s;
		}

		&.animation-delay-2 {
			transition-delay: 0.5s;
		}

		&.animation-delay-3 {
			transition-delay: 0.75s;
		}

		&.animation-delay-4 {
			transition-delay: 1s;
		}

		&.animation-delay-5 {
			transition-delay: 1.25s;
		}

		&.animation-duration-fast {
			transition-duration: 500ms;
		}

		&.animation-duration-slow {
			transition-duration: 1500ms;
		}
	}

	.#{$prefix}animation-fade-up,
	.#{$prefix}animation-fade-left,
	.#{$prefix}animation-fade-right,
	.#{$prefix}animation-fade-up-right,
	.#{$prefix}animation-fade-up-left,
	.#{$prefix}animation-fade-down,
	.#{$prefix}animation-fade-down-right,
	.#{$prefix}animation-fade-down-left {
		transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
	}

	.#{$prefix}animation-fade-in,
	.#{$prefix}animation-scale-in {
		transition-timing-function: linear;
	}

	.#{$prefix}animation-fade-up {
		transform: translate3d(0, $aos-distance, 0);
	}

	.#{$prefix}animation-fade-down {
		transform: translate3d(0, -$aos-distance, 0);
	}

	.#{$prefix}animation-fade-right {
		transform: translate3d(-$aos-distance, 0, 0);
	}

	.#{$prefix}animation-fade-left {
		transform: translate3d($aos-distance, 0, 0);
	}

	.#{$prefix}animation-fade-up-right {
		transform: translate3d(-$aos-distance, $aos-distance, 0);
	}

	.#{$prefix}animation-fade-up-left {
		transform: translate3d($aos-distance, $aos-distance, 0);
	}

	.#{$prefix}animation-fade-down-right {
		transform: translate3d(-$aos-distance, -$aos-distance, 0);
	}

	.#{$prefix}animation-fade-down-left {
		transform: translate3d($aos-distance, -$aos-distance, 0);
	}

	.#{$prefix}animation-scale-in {
		transform: scale(0.95);
	}
}

@include animation-factory();

// @include respond($md, "down") {
// 	@include animation-factory('sm-');
// }

// @include respond($md) {
// 	@include animation-factory('md-');
// }

// @include respond($lg) {
// 	@include animation-factory('lg-');
// }

// @keyframes gradientHover {
// 	0% {
// 		background-position: top left;
// 	}

// 	100% {
// 		background-position: bottom right;
// 	}
// }