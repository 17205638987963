// -----------------------------------------------------------------------------
//! Fonts
// -----------------------------------------------------------------------------

$font:  nunito-sans, Arial, Helvetica, Sans-serif;
$font-accent: termina, Arial, Helvetica, Sans-serif;
$regular: 400;
$medium: 500;
$semi: 600;
$bold: 700;

// -----------------------------------------------------------------------------
//! Colors
// -----------------------------------------------------------------------------

$color-body: 		#293446;
$color-dark:		#231F20;
$color-blue:		#004680;
$color-red: 		#CD470A;
$color-teal:		#007FAD;
$color-border:  #B3B4B6;
$color-green:		#218003;
$color-white:		#ffffff;

// -----------------------------------------------------------------------------
//! Z-indexes
// -----------------------------------------------------------------------------

$z_index-level6: 120;
$z_index-level5: 100;
$z_index-level4: 75;
$z_index-level3: 50;
$z_index-level2: 25;
$z_index-level1: 1;
$z_index-level0: -1;

// -----------------------------------------------------------------------------
//! Breakpoints
// -----------------------------------------------------------------------------

$bp-padding: 1024px;
$bp-contentStack: 860px;
$bp-filterStack: 840px;
