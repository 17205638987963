$bp-nav: 1340px;
$bp-logo: 720px;

body {
	padding: 72px 0 0 0;
}

.header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	padding: 8px 0;
	background: #fff;
	z-index: $z_index-level6 - 1;

	@include respond($bp-nav) {
		padding: 0;
	}
}

.header .container {
	display: flex;
	align-items: center;
	// justify-content: flex-end;
	justify-content: space-between;
	gap: 8px;
	position: relative;
	min-height: 72px;

	@include respond($bp-logo) {
		gap: 16px;
	}

	@include respond(900px) {
		// justify-content: flex-start;
		// gap: 64px;
		justify-content: flex-end;
	}
}

.logoNav {
	display: flex;
	// width: 68px;
	// flex: 0 0 68px;
	// margin: 0 auto 0 0;
	margin: 0;

	@include respond(900px) {
		gap: 32px;
		align-items: center;
		margin-right: auto;
	}
}

.header .logo {
	position: relative;
	z-index: $z_index-level5 + 1;
	margin: 0 auto 0 0;
	display: block;
	flex: 0 0 130px;
	overflow: hidden;

	@include respond($bp-nav) {
		margin: 0;
	}

	@include respond($bp-logo, "down") {
		width: 60px;
		flex: 0 0 60px;
	}

	svg {
		width: 130px;
		height: 56px;
		display: block;

		@include respond($bp-logo, "down") {

			.cls-4,
			.cls-5 {
				display: none;
			}
		}
	}
}

.header-menu {
	z-index: $z_index-level5;

	.header-buttons {
		display: none;

		@include respond($bp-nav, "down") {
			display: flex;
		}
	}

}

.header-menu > ul {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 24px;
	margin: 0;
	padding: 0;
	list-style: none;

	@include respond(1400px, "down") {
		gap: 16px;
	}
}

.header-menu > ul > li {
	font-size: 1.4rem;
	line-height: 1.5;
	margin: 0;
	padding: 0;
	position: relative;

	&:hover {
		svg * {
			fill: $color-teal;
		}
	}

	& > a {
		color: $color-dark;
		text-decoration: none;
		display: block;
		white-space: nowrap;
		line-height: 72px;
		font-weight: bold;

		&:hover {
			color: $color-teal;
		}

		&.is-active {
			color: $color-teal;
		}
	}

	&.has-children {
		display: flex;
		align-items: center;
		gap: 4px;

		& > a {
			flex: 0 0 auto;
		}

		svg {
			flex: 0 0 auto;
			transition: fill 0.4s ease;
		}
	}
}

.header-menu > ul > li > ul {
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	position: absolute;
	top: 100%;
	left: 0%;
	background: #fff;
	width: 300px;
	list-style: none;
	margin: 0;
	padding: 8px 24px;
	border-top: 1px solid rgba(128, 130, 133, 0.05);
	transition: opacity 0.2s ease;

	& > li {
		font-size: 14px;
		padding: 0;
		margin: 0;
		line-height: 20px;
		letter-spacing: -0.02em;
		border-top: 1px solid $color-border;

		&:first-of-type {
			border: none;
		}
	}

	& > li > a {
		display: block;
		padding: 16px 0;
		text-decoration: none;
		color: $color-body;

		&:hover {
			color: $color-teal;
		}
	}
}

.header-menu > ul > li:hover > ul {
	@include respond($bp-nav) {
		pointer-events: auto;
		visibility: visible;
		opacity: 1;
	}
}

.submenu-toggle {
	display: block;
	padding: 0;
	border: 0;
	background: transparent;

	@include respond($bp-nav) {
		padding-top: 6px;
	}
}

.header-buttons {
	gap: 8px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	position: relative;
	z-index: $z_index-level5 + 1;

	@include respond($bp-logo, "down") {
		&.header-buttons-main {

			.btn-outline,
			.btn-blue,
			.search-wrap {
				display: none;
			}
		}
	}

	@include respond($bp-nav) {
		gap: 16px;
		margin-left: auto;
	}

	.btn {
		padding: 7px 8px;
	}
}

.search-nav {
	line-height: 36px;
	background: #fff;
	padding: 0 8px 0 0;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border: none;
	cursor: pointer;
	gap: 8px;

	span {
		padding: 0 10px;
		display: block;
		position: relative;
		top: 2px;
	}

	&:hover {
		color: $color-blue;
	}

}

.hamburger {
	display: block;
	order: 3;
	position: relative;
	z-index: $z_index-level5+1;
	width: 32px;
	height: 32px;
	background: transparent;
	padding: 0 4px;
	margin: 0 0 0 0;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	overflow: hidden;
	flex: 0 0 32px;

	@include respond($bp-nav) {
		display: none;
	}

	.bar {
		background: $color-teal;
		height: 2px;
		width: 24px;
		flex: 0 0 2px;
		transition: all 0.4s ease;

		&:nth-child(2) {
			margin: 6px 0;
		}
	}

	&.is-active {
		.bar1 {
			transform: translate3d(0, 8px, 0) rotate(45deg);
		}

		.bar3 {
			transform: translate3d(0, -8px, 0) rotate(-45deg);
		}

		.bar2 {
			transform: translateX(-32px);
			opacity: 0;
		}
	}

}


@include respond($bp-nav, "down") {
	.header-menu {
		display: none;
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background: #fff;
		z-index: $z_index-level5;
	}

	.header-menu > ul {
		padding: 0;
		margin: 104px 0 40px 0;
		height: calc(100% - 104px);
		overflow: auto;
		display: block;
	}

	.header-menu > ul > li {
		padding: 0 24px 0 24px;
		font-size: 1.8rem;
		line-height: calc(32/18);
		font-family: $font;
		font-weight: 400;
		margin: 0 0 18px 0;
	}

	.header-menu > ul > li > a {
		color: $color-body;
		display: block;
		white-space: normal;
		line-height: 32px;

		&:hover {
			color: $color-blue;
			text-decoration: none;
		}

		&.is-active {
			color: $color-teal;
		}
	}

	.header-menu > ul > li > ul {
		position: static;
		visibility: visible;
		opacity: 1;
		pointer-events: auto;

		& > li {
			font-size: 16px;
			line-height: 24px;
		}
	}

	.header-menu > ul > li.has-children {
		flex-wrap: wrap;
		gap: 8px;

		& > a {
			flex: 0 0 calc(100% - 40px);
		}

		.submenu-toggle {
			height: 32px;
			width: 32px;
			border: 1px solid $color-teal;
			flex: 0 0 32px;
			border-radius: 4px;
			display: flex;
			align-items: center;
			justify-content: center;

			&.is-active svg {
				transform: rotate(-180deg);
			}

			svg {
				position: relative;
				left: -1px;
			}

			svg * {
				fill: $color-teal;
			}
		}

		ul {
			flex: 0 0 100%;
			display: none;
		}
	}

	.header-menu .header-buttons {
		padding: 0 24px;
		gap: 16px;
		flex-wrap: wrap;
		display: flex;
		justify-content: flex-start;
		margin-bottom: 18px;

		&:last-child {
			margin-bottom: 0;
		}

		@include respond(400px, "down") {
			flex-direction: column;
			padding-bottom: 24px;

			.btn-blue,
			.btn-outline {
				width: 100%;
			}
		}
	}

}



@include respond($bp-nav) {
	.header-menu {
		display: block !important;
	}
}


.nowPlaying {
	overflow: auto;
	position: absolute;
	top: calc(100% + 8px);
	left: 0;
	right: 0;
	padding: 10px 24px;
	background: $color-blue;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 16px;

	@include respond($bp-logo) {
		left: 24px;
		right: 24px;
	}

	@include respond($bp-nav) {
		top: 100%;
	}

	svg {
		flex: 0 0 20px;

		* {
			fill: #fff;
		}
	}

	div {
		padding: 1px 0 0 0;
		color: #fff;
		font-size: 1.6rem;
		line-height: 1;
	}

	.nowPlayingLabel {
		font-weight: bold;
		flex: 0 0 119px;
	}

	.nowPlayingSong {
		flex: 1 0 auto;
		white-space: nowrap;
		overflow: auto;
		-ms-overflow-style: none;
		/* IE and Edge */
		scrollbar-width: none;

		/* Firefox */
		&::-webkit-scrollbar {
			display: none;
		}
	}

	.back-to-radio-button {
		color: $color-white;
	}

	.nowPlayingSwitch {
		padding: 1px 0 0;
		color: #fff;
		font-size: 1.6rem;
		line-height: 1;
		flex: 0 0 auto;

		select {
			border: 1px solid #fff;
			border-radius: 4px;
			padding: 4px 8px;
			background-color: #004680;
			color: #fff;
			background-position: 90% center;
			font-size: 12px;
			line-height: 1.5;
			height: 32px;
			padding: 4px 8px;

		}
	}

	.nowPlayingVolume {
		display: none;

		@media screen and (min-width: 768px) {
			display: flex;
			align-items: center;
			width: 120px
		}

		svg {
			margin-right: 4px;
			//opacity: 0.8;
			transition: opacity .2s;

			path {
				fill: none;
			}
		}

		.nowPlayingVolumeSlider {
			appearance: none;
			width: 90px;
			height: 2px;
			background: #ffffff;
			outline: none;
			//opacity: 0.8;
			transition: opacity .2s;

			&::-webkit-slider-thumb {
				-webkit-appearance: none;
				/* Override default look */
				appearance: none;
				width: 16px;
				height: 16px;
				border-radius: 50%;
				background: #ffffff;
				cursor: pointer;
			}

			&::-moz-range-thumb {
				width: 16px;
				height: 16px;
				border-radius: 50%;
				background: #ffffff;
				cursor: pointer;
			}
		}

		&:hover {
			svg {
				opacity: 1;
			}

			.nowPlayingVolumeSlider {
				opacity: 1;
			}
		}
	}
}


// -----------------------------------------------------------------------------
//! Search
// -----------------------------------------------------------------------------

.search-wrap {
	height: 56px;
	display: flex;
	align-items: center;

	@include respond($bp-nav) {
		height: 72px;
	}
}

.search-box {
	display: none;
	position: absolute;
	right: 0;
	top: calc(100% + 8px);
	background: #fff;
	padding: 10px;
	border-radius: 0 0 4px 4px;
	gap: 10px;
	border-top: 1px solid rgba(128, 130, 133, 0.05);

	@include respond($bp-nav) {
		top: 100%;
	}

	&.is-active {
		display: flex;
	}
}

.search-submit {
	padding: 0;
	border: none;
	background: transparent;
	cursor: pointer;

	svg {
		display: block;
	}
}

input[type="search"] {
	border: 1px solid #808285;
	border-radius: 4px;
	line-height: 48px;
	height: 48px;
	width: 300px;
	font-size: 16px;
	color: $color-body;
	font: $font;
	padding: 0 16px;
}


@include respond($bp-nav, "down") {
	.header-menu > ul .header-buttons .search-box {
		display: flex;
		padding: 0;
		position: static;
		width: 100%;

		input {
			width: calc(100% - 64px);
			flex: 0 0 calc(100% - 64px);
		}

		button {
			flex: 0 0 48px;
		}
	}
}



// -----------------------------------------------------------------------------
//! Location Bar
// -----------------------------------------------------------------------------


.location-bar {
	height: 32px;
	max-width: 1368px;
	margin: 0 auto;
	padding: 0 24px;
	text-align: right;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	gap: 8px;
	position: relative;
	z-index: $z_index-level6 - 2;
}

.location-button {
	padding: 0 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 70px;
	border-radius: 3px;
	height: 24px;
	font-size: 12px;
	line-height: 24px;
	background: none;
	border: 1px solid #007fad;
	gap: 4px;
}

.location-label {
	font-size: 12px;
	line-height: 24px;
	flex: 0 0 auto;
}

.location-button-wrap {
	position: relative;
	flex: 0 0 auto;
}

.location-button-dropdown {
	display: none;
	position: absolute;
	background: #fff;
	width: 100%;
	color: #fff;
	top: calc(100%);
	border-radius: 0 0 3px 3px;
	z-index: 55;

	text-align: left;
	border: 1px solid #007fad;
	border-top: 1px solid rgba(0, 127, 173, 0.2);

	a {
		font-size: 12px;
		line-height: 16px;
		text-decoration: none;
		display: block;
		padding: 4px 8px;
		background: #fff;
		transition: background 0.4s ease;
		border-radius: 0 0 3px 3px;

		&:hover {
			background: rgba(0, 127, 173, 0.05);
		}
	}
}

.location-bar .location-button-wrap {
	margin-right: 56px;
}

.location-button-wrap.is-active {
	box-shadow: 0 0 40px rgba(0, 0, 0, .05);
}

.location-button-wrap.is-active .location-button {
	border-radius: 3px 3px 0 0;
	border-bottom: 1px solid transparent;
}

.location-button-wrap.is-active .location-button-dropdown {
	display: block;
}

.location-button-wrap.is-active .location-button svg {
	transform: rotate(180deg);
}

@include respond(1320px, "down") {

	.location-bar {
		display: none;
	}
}


.mobile-location {
	display: inline-flex;
	align-items: center;
	justify-content: flex-start;
	gap: 8px;
}


// -----------------------------------------------------------------------------
//! New Location Toggle
// -----------------------------------------------------------------------------

.location-toggle-wrap {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 4px;
}

.logoNav > .location-toggle-wrap {
	@include respond(900px, "down") {
		display: none;
	}
}

.location-toggler {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 4px;

	@include respond(900px) {
		display: none;
	}

	.location-toggles {
		opacity: 0;
		pointer-events: none;
		position: absolute;
		top: 0;
		right: 0;
		// width: 100%;
		// height: 100%;
		padding: 4px;
		border-radius: 5px;
		background: rgba(#57595C, 0.12);
		// display: inline-flex;
		// justify-content: stretch;
		// flex-wrap: wrap;
		// transition: all 0.4s ease;

		.location-toggle-button {
			display: block;
			white-space: nowrap;
			font-size: 12px;
			line-height: 15px;
			padding: 4px 8px 3px 8px;
			text-decoration: none;
			border-radius: 3px;
			color: #293446;

			+ a {
				margin-top: 4px;
			}
		}

		&.is-dallas {
			.location-toggle-button[data-site="dallas"] {
				background: #fff;
				font-weight: bold;
				color: #07487E;
			}
		}

		&.is-waco {
			.location-toggle-button[data-site="waco"] {
				background: #fff;
				font-weight: bold;
				color: #07487E;
			}
		}
	}

	.current-site {
		opacity: 1;
		padding: 4px;
		border-radius: 5px;
		background: rgba(#57595C, 0.12);

		span {
			display: block;
			white-space: nowrap;
			font-size: 12px;
			line-height: 15px;
			padding: 4px 8px 3px 8px;
			text-decoration: none;
			border-radius: 3px;
			color: #293446;
			background: #fff;
			font-weight: bold;
			color: #07487E;
		}
	}
}

.location-toggler.is-open {
	.current-site {
		opacity: 0;
	}

	.location-toggles {
		opacity: 1;
		pointer-events: all;
	}
}


.location-toggle-icon {
	flex: 0 0 14px;
	width: 14px;
	height: 14px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	background-image: url('../svg/map-pin.svg');
}

.location-toggle {
	padding: 4px;
	border-radius: 5px;
	background: rgba(#57595C, 0.12);
	display: flex;
	justify-content: flex-start;
	align-items: stretch;

	a {
		display: block;
		white-space: nowrap;

		font-size: 12px;
		line-height: 15px;
		padding: 4px 8px 3px 8px;
		text-decoration: none;
		border-radius: 3px;
		color: #293446;
	}

	&.is-waco a:last-child {
		background: #fff;
		font-weight: bold;
		color: #07487E;
	}

	&.is-dallas a:first-child {
		background: #fff;
		font-weight: bold;
		color: #07487E;
	}

	a:hover {
		color: #07487E;
	}
}

.mobile-location .location-toggle-icon {
	width: 18px;
	height: 18px;
	flex: 0 0 18px;
}

.mobile-location .location-toggle a {
	font-size: 14px;
	line-height: 16px;
	padding: 5px 16px;
}



// -----------------------------------------------------------------------------
//! Nextafter code
// -----------------------------------------------------------------------------

.location-toggle-wrap {
  position: relative !important;
}
.callout {
  position: absolute;
  display: inline-block;
  padding: 10px 20px;
  background-color: #00a3d8;
  border-radius: 4px;
  font-weight: bold;
  font-size: 16px;
  color: #fff;
  text-align: center;
  top: 45px;
  right: -36px;
  z-index: 9999;
}
.callout::after {
  content: "";
  position: absolute;
  bottom: 100%;
  /* Position arrow above the callout */
  left: 50%;
  margin-left: -10px;
  /* Center the arrow horizontally */
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #00a3d8 transparent;
}
.callout::before {
  content: "";
  position: absolute;
  bottom: calc(100% + 1px);
  /* Position arrow border exactly above the callout border */
  left: 50%;
  margin-left: -10px;
  /* Center the arrow horizontally */
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #ccc transparent;
}
.pulse {
  animation-name: pulse;
  -webkit-animation-name: pulse;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-iteration-count: 2;
  -webkit-animation-iteration-count: 2;
  animation-timing-function: ease-in;
  -webkit-animation-timing-function: ease-in;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
}
@keyframes pulse {
  0% {
	transform: scale(1) translateZ(0);
	-webkit-transform: scale(1) translateZ(0);
	opacity: 1;
  }
  50% {
	transform: scale(0.95) translateZ(0);
	-webkit-transform: scale(0.95) translateZ(0);
	opacity: 1;
  }
  100% {
	transform: scale(1) translateZ(0);
	-webkit-transform: scale(1) translateZ(0);
	opacity: 1;
  }
}