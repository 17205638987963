.articleInfo {
	padding: 24px 0 32px 0;
	@include respond($bp-padding) {
		padding: 80px 0 64px 0;
	}
	@include respond(860px) {
		background: radial-gradient(46.26% 46.26% at 0% 0%, rgba(0, 127, 173, 0.3) 0%, rgba(0, 127, 173, 0) 100%);
	}
}

.articleInfo .container {
	max-width: 700px;
}

.articleInfo-title {
	font-size: 2.6rem;
	line-height: calc(34/26);
	letter-spacing: -0.02em;
	font-family: $font-accent;
	font-weight: $bold;
	color: $color-blue;
	margin: 0 0 32px 0;
	@include respond($bp-padding) {
		font-size: 4.0rem;
		line-height: calc(56/40);
	}
}

.articleInfo-meta {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	gap: 24px 64px;
	flex-wrap: wrap;
}
.articleInfo-meta-left {
	flex: 0 0 auto;
}

.articleInfo-meta-left-author {
	font-weight: $bold;
	line-height: calc(26/16);
}

.articleInfo-meta-left-date {
	line-height: calc(26/16);
}

.articleInfo-meta-right {
	flex: 0 0 auto;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-weight: $bold;
	font-size: 1.6rem;
	gap: 8px;
	line-height: calc(26/16);
	svg {
		flex: 0 0 24px;
		width: 24px;
		height: 24px;
	}
}