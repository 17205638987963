// -----------------------------------------------------------------------------
//! Modal Styling
// -----------------------------------------------------------------------------

.modal-overlay {
	display: none;
	position: fixed;
	top:0;
	bottom:0;
	left:0;
	right:0;
	height:100%;
	background: rgba(0,0,0, 0.25);
	z-index: $z_index-level6 + 11;
	opacity: 0;	
}

.modal-wrapper {
	position: fixed;
	padding:10px 10px 0 10px;
	top:0;
	right:0;
	bottom:0;
	left:0;
	height:100%;
	z-index: $z_index-level6 + 12;
	overflow: auto;
	display: none;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	opacity: 0;
	&.is-active {
		display: flex;
	}
}

.modal {
	position: relative;
	margin-bottom:10px;
	width:100%;	
	max-height:100%;
	display: block;
	align-items: center;
	justify-content: center;
	&.modal-small {
		max-width: 488px;
	}
}


body.has-modal {
	overflow: hidden;	
	.modal-overlay {
		display: block;
	}
}


// -----------------------------------------------------------------------------
//! Modal Innards
// -----------------------------------------------------------------------------

.modal-inner-wrap {
	padding: 64px;
	overflow: hidden;
	background:#fff;
	border-radius: 5px;
	position: relative;
	max-width: 864px;
	margin: 0 auto;
	top:-200px;
}


.modal-content {
	width:100%;
	img {
		width:100%;
		height:auto;
	}
}

.modal-content-window {
	height: 300px;
	overflow: auto;
	margin: 32px 0 0 0;
	p {
		font-size: 1.6rem;
		line-height: calc(26/16);
	}	
}

.modal-subtitle {
	font-weight: $bold;
	font-size: 1.8rem;
	line-height: calc(32/18);
	margin: 32px 0 16px 0;
	color: $color-body;
	font-family: $font;
}

// -----------------------------------------------------------------------------
//! Actions
// -----------------------------------------------------------------------------

.modal-actions {
	margin: 32px 0 0 0;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 24px;
}

.modal-cancel {
	cursor: pointer;
	background: transparent;
	padding: 0;
	font-size: 1.8rem;
	line-height: calc(32/18);
	color: $color-body;
	transition: color 0.4s ease;
	font-weight: $bold;
	&:hover {
		color: $color-teal;		
	}
}

.modal-actions .btn {
	font-size: 1.6rem;
	line-height: 1.5;
	letter-spacing: -0.02em;
	padding: 16px 32px;
	
}

// -----------------------------------------------------------------------------
//! Modal Close
// -----------------------------------------------------------------------------


.modal-close {
	border:none;
	background: transparent;
	position: absolute;
	height:42px;
	width:42px;
	top:16px;
	right:16px;
	z-index: 2;
	cursor: pointer;
	background-image: url(../svg/modal-close-blue.svg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;	
}

.modal-close:focus {
	outline: none;
}





