$bp-app: 600px;

.appCallout {
	
	background-color: $color-teal;
	background-size: cover;
	background-repeat: no-repeat;
	
	@include respond($bp-app, "down") {
		background-image: url(../webp/appCallout-mobile.webp);
		padding: 310px 0 64px 0;
		background-position: center top;
	}
	@include respond($bp-app) {
		background-image: url(../webp/appCallout.webp);
		padding: 64px 0;
		background-position: center center;
	}
	@include respond($bp-padding) {
		padding: 120px 0;
	}
	
	.section-title {
		color: #fff;
		margin-bottom: 32px;
		@include respond($bp-padding) {
			margin-bottom: 48px;
		}
	}
}


.appCallout-inner {
	max-width: 640px;
	margin: 0 auto;
	text-align: center;
	@include respond($bp-app) {
		text-align: left;
 		margin: 0 0 0 auto;		
	}
}

.appCallout-pre {
	color: #fff;
	font-size: 1.6rem;
	line-height: calc(26/16);
	margin: 0 0 8px 0;
	@include respond($bp-padding) {
		font-weight: $bold;
		font-size: 1.8rem;
		line-height: calc(32/18);
	}
}

.appCallout-inner p {
	color: #fff;
	margin: 0 0 48px 0;
	font-size: 1.8rem;
	line-height: calc(32/18);
	@include respond($bp-padding) {
		font-size: 2rem;
		line-height: calc(32/20);
	}
 }
 
 .qr {
	 display: flex;
	 align-items: center;
	 justify-content: flex-start;
	 gap: 32px;
	 img {
		 flex: 0 1 190px;
		 min-width: 140px;
	 }
	 
	 @include respond($bp-app, "down") {
		display: none;	 
	 }
 }
 
.qr-text {
	max-width: 330px;
	font-weight: $medium;
	font-size: 2rem;
	line-height: calc(32/20);
	letter-spacing: -0.02em;	 
	color: #FFFFFF;
}

.badges {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-wrap: wrap;
	gap: 24px 16px;
	a {
		display: block;
		img {
			display: block;
			height: 48px;
			width: auto;
		}
	}
	@include respond($bp-app) {
		display: none;
	}
}


// -----------------------------------------------------------------------------
//! Resources Page
// -----------------------------------------------------------------------------

.page-resources {
	
	.appCallout {
		background-color: #fff;
		background-repeat: no-repeat;
		
		@include respond($bp-app) {
			background-image:url(../webp/resources-verses.webp);
			
		}
		
		@include respond(1100px, "down") {
			background-position: left -100px top;
		}
		@include respond(660px, "down") {
			background-position: left -160px top;
		}
		
		@include respond($bp-app, "down") {
			background-image: url(../webp/resources-verses-mobile.webp);
			background-position: center top;
			padding-top: 0;
			&:before {
				content: "";
				position: relative;
				padding-top: 100%;
				width: 100%;
				display: block;
			}
		}
		
		.appCallout-inner {
			@include respond(900px, "down") {
				max-width: 400px;
			}
		}
	}
	
	.appCallout .section-title {
		color: $color-blue;
		@include respond($bp-app, "down") {
			text-align: left;	
		}
	}
	
	.appCallout p {
		color: $color-body;
		@include respond($bp-app, "down") {
			text-align: left;	
		}
	}
}