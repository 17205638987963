.articles {
	margin: 0 0 64px 0;
	@include respond($bp-padding) {
		margin: 0 0 120px 0;
	}
}

.articles-grid {
	display: grid;
	gap: 28px;
	grid-template-columns: 1fr;
	@include respond($bp-padding) {
		gap: 32px;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		grid-template-rows: 1fr 1fr 1fr 1fr;
	}
}

.article {
	
	background: #fff;
	box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 0;
	@include respond($bp-padding) {
		grid-column: 2;
	}
}

.article-image {
	width: 30%;
	flex: 0 0 30%;
	height: 100%;
	background-color: $color-blue;
	border-radius: 4px 0 0 4px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	display: block;
	position: relative;
	@include respond($bp-padding) {
		width: calc(192/644 * 100%);
		flex: 0 0 calc(192/644 * 100%);
	}
	&:before {
		content: "";
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		position: absolute;
		background: linear-gradient(0deg, rgba(0, 127, 173, 0.9) 4.51%, rgba(0, 127, 173, 0) 34.67%);
		z-index: $z_index-level1;
	}
}

.article-inner {
	padding: 16px;
	@include respond($bp-padding) {
		padding: 24px;
	}
}

.article-date {
	color: $color-dark;
	font-weight: $semi;
	font-size: 1.2rem;
	line-height: calc(18/12);
	@include respond($bp-padding) {
		font-size: 1.8rem;
		line-height: calc(32/18);	
	}
	@include respond(480px, "down") {
		display: none;
	}
}

.article-title {
	font-size: 1.6rem;
	line-height: calc(24/16);
	letter-spacing: -0.02em;
	font-weight: $medium;
	font-family: $font-accent;
	color: $color-dark;
	text-decoration: none;
	&:hover {
		color: $color-teal;
	}
	@include respond($bp-padding) {
		font-size: 2rem;
		line-height: calc(32/20);
	}
}

.article-meta {
	@include respond($bp-padding) {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		gap: 24px;
	}
}

.article-author {
	font-weight: $semi;
	color: $color-dark;
	font-size: 1.4rem;
	line-height: calc(21/14);	
	@include respond($bp-padding) {
		font-size: 1.8rem;
		line-height: calc(32/18);
	}
	//a,
	span {
		text-decoration: none;
		color: $color-dark;
		// &:hover {
		// 	color: $color-teal;
		// }
		&:after{
			content:", ";
		}
		&:last-of-type:after{
			content:"";
		}
	}
}

.article-time {
	color: $color-dark;
	font-weight: $bold;
	font-size: 1.4rem;
	line-height: calc(21/14);
	@include respond(480px, "down") {
		display: none;
	}
}


.article.is-featured {
	box-shadow: none;
	flex-direction: column;
	@include respond(480px) {
		display: grid;
		grid-template-columns: 30% 1fr;	
	}
	@include respond($bp-padding) {
		grid-column: 1;
		grid-row: 1 / span 4;
		display: block;
	}
	.article-inner {
		@include respond($bp-padding) {
			padding: 0;
		}
	}
	.article-image {
		width: 100%;
		height: 0;
		padding-top: 100%;
		border-radius: 4px;
		position: relative;
		@include respond($bp-padding) {
			margin: 0 0 42px 0;
		}
	}
	
	.article-date {
		font-weight: $bold;
		font-size: 1.2rem;
		line-height: calc(18/12);
		display: block;
		text-transform: uppercase;
		margin: 0 0 8px 0;
		@include respond($bp-padding) {
			font-size: 1.6rem;
			line-height: calc(26/16);
			margin: 0;
		}
	}
	
	.article-title {
		font-size: 1.8rem;
		line-height: calc(24/18);
		margin: 0 0 8px 0;
		display: block;
		@include respond($bp-padding) {
			font-size: 2.8rem;
			line-height: calc(40/28);	
		}
	}
	.article-author {
		display: block;
	}
	.article-time {
		font-size: 1.2rem;
		line-height: calc(18/12);
		display: block;
		@include respond($bp-padding) {
			font-size: 1.6rem;
			line-height: calc(26/16);
		}
	}
}


.featured-article {
	position: absolute;
	left: 0;
	top: 0;
	padding: 8px;
	background: #fff;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 10px;
	font-size: 1.6rem;
	line-height: calc(26/16);
	color: $color-dark;
	font-weight: bold;
	z-index: $z_index-level1 + 1;
	svg {
		position: relative;
		top: -1px;
	}
	
	@include respond(640px, "down") {
		font-size: 1.2rem;
		line-height: calc(16/12);
	}
}
