.teaching {
	padding: 64px 0;
	
	@include respond($bp-padding) {
		padding: 120px 0;
	}
	
	.section-title {
		text-align: center;
		margin: 0 0 32px 0;
		@include respond($bp-padding) {
			margin: 0 0 64px 0;
		}
	}
}

.teaching-tabs-wrap {
	width: 100%;
	overflow: auto;
	text-align: center;
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
	&::-webkit-scrollbar {
		display: none;
	}	
}

.teaching-tabs {
	display: inline-flex;
	justify-content: flex-start;
	align-items: center;
	gap: 24px;
	* {
		flex: 0 0 auto;
	}
}

.teaching-tab {
	background: transparent;
	padding: 0 24px 16px 24px;
	color: $color-dark;
	border-bottom: 3px solid transparent;
	font-size: 1.6rem;
	line-height: calc(26/16);
	font-weight: bold;
	transition: border 0.4s ease;
	@include respond($bp-padding) {
		font-size: 1.8rem;
		line-height: calc(32/18);
	}
	&.is-active {
		border-color: $color-red;
	}
}


.teaching-blocks {
	margin: 24px 0 0 0;
	@include respond($bp-padding) {
		margin: 64px 0 0 0;
	}
}

.teaching-swiper {
	@include respond($bp-padding, "down") {
		width: 100%;
		overflow: auto;
		// -ms-overflow-style: none;  /* IE and Edge */
		// scrollbar-width: none;  /* Firefox */
		// &::-webkit-scrollbar {
		// 	display: none;
		// }	
	}
}
.teaching-swiper .teaching-wrapper{
	@include respond($bp-padding) {
		display: grid;
		grid-template-columns: repeat(3, minmax(0, 1fr));
		gap: 32px;
		transform: none !important;
	}
	
	@include respond($bp-padding, "down") {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		gap: 8px;
		
		.teaching-slide {
			//margin: 0 !important;
			width: 272px;
			flex: 0 0 272px;
		}
	}
	// 
	// .teaching-slide {
	// 	height: calc((100% - 8px) / 2) !important;
	// }
	// .teaching-slide {
	// 	height: calc((100% - 8px) / 2) !important;
	// }
	
	.teaching-slide-inner {
		display: block;
		position: relative;
		padding: calc((274 / 418) * 100%) 0 0 0;
		background-color: #f3f3f3;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		height: 0;
		overflow: hidden;
		border-radius: 8px;
		text-align: left;
		width: 100%;
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			background: linear-gradient(0deg, rgba(0, 127, 173, 0.9) 4.51%, rgba(0, 127, 173, 0) 34.67%);
		}
	}
	
}

.teaching-name {
	position: absolute;
	top: 0;
	left: 0;
	background: #fff;
	padding: 8px;
	font-size: 1.4rem;
	line-height: calc(21/14);
	color: $color-dark;
	font-weight: $bold;
	z-index: $z_index-level1 + 1;
}

.teaching-title {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 16px;
	color: #fff;
	font-size: 1.6rem;
	line-height: calc(24/16);
	font-weight: $medium;
	font-family: $font-accent;
	letter-spacing: -0.02em;
	z-index: $z_index-level1 + 1;
	@include respond($bp-padding) {
		font-size: 2.0rem;
		line-height: calc(32/20);
	}
}


// -----------------------------------------------------------------------------
//! Announcement
// -----------------------------------------------------------------------------

.page-teaching .pageTitle-announcement {
	display: flex;
	gap: 16px 32px;
	align-items: center;
	justify-content: space-between;

	.btn {
		margin-top: 0;
	}
}