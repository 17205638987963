.pagination {
	grid-column: 1 / span 3;
	@include respond(1100px, "down") {
		grid-column: 1 / span 2;
	}
	@include respond(840px, "down") {
		grid-column: 1 / span 3;	
	}
	@include respond(640px, "down") {
		grid-column: 1 / span 2;
	}
	@include respond(440px, "down") {
		grid-column: 1 / span 1;
	}
	
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 16px;
	a, span {
		width: 32px;
		height: 32px;
		border-radius: 50%;
		background: #fff;
		box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
		flex: 0 0 32px;
		text-align: center;
		line-height: 32px;
		color: #808285;
		text-decoration: none;
		font-size: 1.6rem;
		font-weight: $bold;
		svg {
			width: 10px;
			height: 10px;
			* {
				fill: #808285;
			}
		}
		
		&:hover {
			color: $color-blue;
			svg * {
				fill: $color-blue;
			}
		}
		
		&.is-current {
			background: $color-blue;
			color: #fff;
			pointer-events: none;
		}
		&.is-hidden {
			display: none;
		}
	}
}