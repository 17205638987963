// -----------------------------------------------------------------------------
//! GENERAL
// -----------------------------------------------------------------------------

html {
	height: 100%;
	background:#fff;
	overflow-x: clip;
}

body {
	position: relative;
	min-height: 100%;
	overflow-x: clip;
}

.container {
	max-width: 1368px;
	margin: 0 auto;
	padding: 0 24px;	
}

#skip-link {
	position: absolute;
	top: -100%;
	left: 32px;
	z-index: $z_index-level6;
	background: $color-blue;
	padding: 3px 6px;
	border-radius: 0 0 4px 4px;
	font-size: 12px;
	color: #fff;
	border: 1px dashed #fff;
	border-top: none;
	text-decoration: none;
	font-weight: $regular;
	&:focus {
		top: 0;
	}
}
