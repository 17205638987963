// -----------------------------------------------------------------------------
//! SIZING
// -----------------------------------------------------------------------------

html {
	font-size: 62.5%;
}

body {
	font-size: 1.6rem;
	font-family: $font;
	color: $color-body;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// -----------------------------------------------------------------------------
//! HEADINGS
// -----------------------------------------------------------------------------

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $font-accent;
	line-height: 1;
	font-weight: 700;
	margin: 1em 0;
	color: $color-blue;
	letter-spacing: -0.02em;

	&:first-child {
		margin-top: 0;
	}
}

.section-title-wrap {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
	gap: 8px 24px;
	margin: 0 0 24px 0;

	@include respond($bp-padding) {
		margin: 0 0 64px 0;

		&.live-subtitle {
			margin-bottom: 0;

			.listen-live-subtitle {
				margin-bottom: 0;
			}
		}
	}
}

.section-title {
	font-size: 2.4rem;
	line-height: calc(34/24);
	font-weight: $bold;
	margin: 0;

	@include respond($bp-padding) {
		font-size: 4rem;
		line-height: calc(56/40);
	}
}

.section-title-pre {
	display: block;
	font-family: $font;
	color: $color-dark;
	font-weight: $bold;
	font-size: 1.4rem;
	line-height: calc(21/14);

	@include respond($bp-padding) {
		font-size: 1.8rem;
		line-height: calc(32/18);
	}
}

// -----------------------------------------------------------------------------
//! COPY
// -----------------------------------------------------------------------------

p {
	line-height: calc(25/16);
	margin: 1em 0;
	font-size: 1.6rem;

	&:first-child {
		margin-top: 0;
	}
}

a {
	text-decoration: underline;
	transition: all 0.4s ease;
	color: $color-teal;
}

svg * {
	transition: all 0.4s ease;
}

ul,
ol {
	margin: 1em 0;
	padding: 0 0 0 24px;

	li {
		padding: 0 0 0 8px;
		margin: 0 0 8px 0;
		line-height: 1.5;
		position: relative;
	}
}


blockquote {
	font-style: italic;
	font-size: 1.6rem;
	line-height: calc(26/16);
	padding: 1em;
}