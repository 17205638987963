.showMedia {
	padding: 0 0 96px 0;
	.swiper {
		height: 328px;
	}

	.swiper-slide {
		height: calc((100% - 32px) / 2) !important;
	}

	.showMedia-item {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 16px;
		padding: 24px;
		background: #fff;
		box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
		border-radius: 4px;
		a {
			display: block;
			flex: 0 0 64px;
		}
		button {
			background-color: transparent;
			text-align: left;
			padding: 0;
			&.is-playing {
				svg * {
					fill: $color-teal;
				}
				&:hover svg * {
					fill: $color-blue;
				}
			}
		}
	}

	.showMedia-content {
		text-align: left;
		flex: 1 1 auto;
	}

	.showMedia-title {
		font-size: 2rem;
		line-height: calc(32/20);
		color: $color-teal;
		font-family: $font-accent;
		letter-spacing: -0.02em;
		font-weight: $medium;
		display: block;
		text-decoration: none;
		max-height: 64px;
		overflow: hidden;
		position: relative;

		&:hover {
			color: $color-blue;
		}
		&.overflow {
			height: 64px;
			overflow: hidden;
			position: relative;
			&:after {
				content: "...";
				position: absolute;
				background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
				bottom: 0;
				right: 0;
				padding: 0 0 0 24px;
			}
		}
	}
	.showMedia-date {
		color: $color-dark;
		font-weight: $bold;
		font-size: 1.8rem;
		line-height: calc(32/18);
	}

	.swiper-arrows {
		padding-top: 32px;
	}
}
