.page-donate,
.section-donatePages {
	
	.header {
		border-bottom: 1px solid rgba(128, 130, 133, 0.05);
	}
	.header-menu {
		display: none !important;
	}
	
	.header-buttons {
		display: none !important;
	}
	
	.hamburger {
		display: none !important;
	}
	
	#now-playing {
		display: none !important;
	}
	
	.content-restrainedText {
		
		margin: 0;
		background-image: radial-gradient(40% 100% at 0 0,rgba(0,127,173,.3) 0,rgba(0,127,173,0) 100%);
		padding-top: 64px;
		
		@include respond($bp-padding) {
			padding-top: 120px;	
		}
		
		.container {
			max-width: 688px;
		}
		
		.section-title {
			font-family: $font-accent;
			color: $color-dark;
			font-weight: $medium;
			font-size: 2.4rem;
			line-height: 24px;
			margin: 0 0 32px 0;
		}
	}		
	
	#audio-player {
		display: none;
	}
	
}


.donateForm {
	padding: 64px 0;
	background-image: url(../webp/donate-bg.webp);
	background-repeat: no-repeat;
	background-size: auto;
	background-position: center bottom;
	
	@include respond($bp-padding) {
		padding: 120px 0;
	}
	
	.container {
		max-width: 688px;
	}
	
	form {
		box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
		background: #fff;
		padding: 24px;
		
		@include respond($bp-padding) {
			padding: 48px;
		}
	}
	
	.freeform-row {
		margin: 0;
		display: grid;
		gap: 8px;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		@include respond($bp-padding) {
			gap: 32px;	
		}
		
		
	}
	.freeform-row .freeform-column {
		margin: 32px 0 0 0;
		position: relative;
	}
	
	.freeform-row.freeform-row-1 .freeform-column {
		margin: 0;
	}
	
	.freeform-column-12 {
		grid-column: 1 / span 2;		
	}
	
	input.freeform-input {
		width: 100%;
		max-width: 100%;
	}
	
	h3 {
		font-family: $font-accent;
		font-weight: $medium;
		font-size: 2rem;
		line-height: 32px;
		letter-spacing: -0.02em;		
		color: $color-blue;
		border-bottom: 1px solid $color-border;
		padding-bottom: 10px;
		margin: 0;
	}
	
	h4 {
		font-family: $font-accent;
		font-weight: $medium;
		font-size: 2rem;
		line-height: 32px;
		letter-spacing: -0.02em;		
		color: $color-blue;
		margin: 0;
		color: $color-body;
	}
	
	.radio-grid {
		gap: 24px;
		
		.form-radio {
			@include respond($bp-padding, "down") {
				flex: 0 0 100%;
				text-align: center;
			}
		}
		.form-button-label {
			padding: 16px 32px;
			line-height: 24px;
			font-size: 2rem;
			color: $color-teal;
			border:1px solid $color-teal;
			font-weight: $bold;
			border-radius: 4px;
			letter-spacing: -0.02em;
			transition: all 0.4s ease;
			&:before,
			&:after {
				display: none;
			}
		}
		
		.form-radio input:checked + label {
			background: $color-teal;
			color: #fff;
		}
		.form-radio input.form-check-input:checked + label {
			color: $color-dark;
			background: none;
		}
	}
	
	
	
	input.freeform-input.other-amount {
		width: calc(100% - 24px);
		margin-left: 24px;
	}
	
	label.freeform-label.other-amount {
		position: absolute;
		left: 0;
		font-size: 16px;
		line-height: 26px;
		top: 50%;
		transform: translateY(-50%);
	}

}

.payment-wrap {
	margin: 32px 0 0 0;
	background: rgba(0, 127, 173, 0.05);	
	border: 2px solid #004680;
	border-radius: 4px;
	padding: 16px;
	position: relative;
	@include respond($bp-padding) {
		padding: 32px;	
	}
	&:after {
		content: "";
		width: 48px;
		height: 48px;
		position: absolute;
		top: 32px;
		right: 32px;
		background-image: url(../svg/lock.svg);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		@include respond($bp-padding, "down") {
			width: 32px;
			height: 32px;
			top: 16px;
			right: 16px;
		}
	}
}


.content-restrainedText + .donateForm {
  padding-top: 0;
}