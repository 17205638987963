$bp-herostack: 860px;

.hero {
	position: relative;
	@include respond($bp-herostack) {
		padding: 180px 0 170px;
	}
}

.hero-spacer {
	background-color: #fff;
	background-image: url(../webp/home-hero.webp);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: right center;
	padding-top: calc((280 / 376) * 100%);
	
	@include respond(600px) {
		padding-top: calc((300 / 600) * 100%);
	}
	@include respond($bp-herostack) {
		padding: 0;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		z-index: $z_index-level1;
	}	
}

.hero .container {
	position: relative;
	z-index: $z_index-level1 + 1;
}
.hero-title {
	max-width: 750px;
	color: #fff;
	font-weight: $medium;
	font-size: 3.2rem;
	line-height: calc(48/32);
	letter-spacing: -0.02em;
	span {
		font-weight: $bold;
		display: block;
		font-size: 4.2rem;
		line-height: calc(56/42);
		letter-spacing: -0.02em;
	}
	
	@include respond(1200px, "down") {
		max-width: 490px;		
	}
	
	@include respond($bp-herostack, "down") {
		padding: 24px 0 0 0;
		max-width: 100%;
		color: $color-teal;
		font-size: 2rem;
		line-height: calc(34/20);
		span {
			display: inline;
			color: $color-blue;
			font-size: 2.6rem;
			line-height: calc(34/26);
		}
	}
}


.hero-wrap {
	@include respond($bp-herostack, "down") {
		background: radial-gradient(82% 100% at 0% 0%, rgba(0, 127, 173, 0.3) 0%, rgba(0, 127, 173, 0) 100%);
		padding-bottom: 46px;
	}
}

.hero-wrap .btn {
	margin-top: 32px 0 0 0;
}

