
.page-business-impact-partners .pageTitle .btn {
	margin: 0;
}

.page-business-impact-partners .pageTitle .pageTitle-announcement {
	justify-content: space-between;
	gap: 16px 32px;
}


.page-business-impact-partners .pageTitle .school-announcement {
	font-family: $font-accent;
	font-weight: $medium;	
	letter-spacing: -0.02em;
	font-size: 18px;
	line-height: 24px;
	@include respond($bp-padding) {
		font-size: 22px;
		line-height: 32px;	
	}
}

.businessGrid-category {
	margin-top: 40px;
	&:first-of-type {
		margin: 0;
	}
}
.businessGrid-category ul {
	margin: 24px 0 0 0;
	list-style: none;
	padding: 0;
	display: grid;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	gap: 24px;
	li {
		padding: 0;
		margin: 0;
	}
}