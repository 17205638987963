.eventsForm {
	background-image: url(../webp/form-blur.webp);
	background-position: left bottom;
	background-repeat: no-repeat;
	background-size: auto auto;
	padding: 64px 0;
	@include respond($bp-padding) {
		padding: 120px 0;	
	}
	
	.container {
		max-width: 688px;
	}
	
	.section-title {
		 text-align: center;
		 margin: 0 0 24px 0;
		 @include respond($bp-padding) {
			 margin: 0 0 48px 0;
		 }
	}
	
	p {
		padding: 0 15px;
	}
	
	
	.freeform-row .freeform-column-12 {
	  width: 100%;
	}
	
	.freeform-row .freeform-column-6 {
	  width: 50%;
	}
	
	.freeform-row .freeform-column{
		padding: 10px 15px;
		float: left;
	}
	
	.freeform-row .freeform-column .freeform-input {
	  width: 100%;
	  display: block;
	  box-sizing: border-box;
	}
	
	.freeform-row::after {
	  content: "";
	  display: table;
	  clear: both;
	}
	
	.ff-form-success {	
		text-align: center;
		padding-top: 84px;	
		position: relative;
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			width: 72px;
			height: 72px;
			background-image: url(../svg/icon-success.svg);
			background-size: 72px 72px;
			background-repeat: no-repeat;
		}
		
		p {
			font-weight: $bold;
			font-size: 2rem;
			line-height: calc(32/20);
			color: $color-body;
		}
	}
	
}
