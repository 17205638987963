.onAir {
	padding: 22px 0 0 0;

	@include respond($bp-padding) {
		padding: 176px 0 0 0;
	}

	@include respond($bp-contentStack) {
		background: radial-gradient(46.26% 46.26% at 0% 0%, rgba(0, 127, 173, 0.3) 0%, rgba(0, 127, 173, 0) 100%);
	}

	.section-title {
		text-align: center;
	}
}

.onAir .showTabs {
	max-width: 640px;
	margin: 48px auto;
	position: relative;
	z-index: $z_index-level4;
}


.onAir .blogGrid-entries {
	display: none;
}

.blogGrid-entries.showTabs-panel.is-active {
	display: grid;

	&.onAirTabs {}
}


.onAir {

	.blogGrid-entry {

		@include respond(440px, "down") {
			display: block;

			.blogGrid-entry-image {
				width: auto;
				height: 0;
				padding-top: 100%;

			}

			.blogGrid-entry-content {
				padding: 16px 0 0 0;
			}
		}
	}

	.blogGrid-entry-image::before {
		display: none;
	}

	.blogGrid-entry-image.is-desktop {
		position: relative;
		box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);

		&:hover .blogGrid-entry-overlay {
			opacity: 1;
			z-index: $z_index-level1 + 2;
		}

		@include respond(768px, "down") {
			display: none;
		}
	}

	.blogGrid-entry-image.is-mobile {
		display: none;
		position: relative;
		box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);

		@include respond(768px, "down") {
			display: block;
		}

		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: radial-gradient(36.51% 35.29% at 100% 0%, rgba(0, 127, 173, 0.7) 0%, rgba(0, 127, 173, 0) 100%);
			z-index: $z_index-level1;
		}
	}

	.blogGrid-entry-info {
		position: absolute;
		top: 20px;
		right: 20px;
		background: none;
		padding: 0;
		cursor: pointer;
		border: 0;
		z-index: $z_index-level1 + 1;
	}

	.blogGrid-entry-overlay {
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: #fff;
		z-index: $z_index-level1;
		overflow: auto;
		transition: opacity 0.4s ease;
	}

	.has-overlay .blogGrid-entry-overlay {
		opacity: 1;
		z-index: $z_index-level1 + 2;
	}

	.blogGrid-entry-overlay-close {
		position: absolute;
		top: 20px;
		right: 20px;
		background: none;
		padding: 0;
		cursor: pointer;
		border: 0;
		z-index: $z_index-level1 + 1;
	}

	.blogGrid-entry-inner {
		max-height: calc(100% - 96px);
		position: absolute;
		left: 0;
		right: 0;
		padding: 0 32px;
		top: 50%;
		transform: translateY(-50%);
		overflow: auto;
	}

	.blogGrid-entry-inner-text {
		color: $color-body;
		font-size: 1.6rem;
		line-height: calc(26/16);
		margin: 0 0 32px;
	}

	.blogGrid-entry-times {
		font-size: 1.6rem;
		line-height: calc(26/16);
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 8px;
		flex-wrap: wrap;

		img {
			flex: 0 0 24px;
		}

		span:after {
			content: " | ";
		}

		span:last-of-type:after {
			content: "";
		}
	}

}