.email {
	background: $color-blue;
	padding: 64px 0;
	@include respond($bp-padding) {
		padding: 120px 0;
	}
	
	.section-title {
		color: #fff;
		margin: 0 0 32px 0;
	}
	p {
		color: #fff;
		font-size: 1.6rem;
		line-height: calc(26/16);
		@include respond($bp-padding) {
			font-size: 1.8rem;
			line-height: calc(32/18);
		}
	}
}

.email .container {
	
	@include respond(800px) {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		gap: 64px;	
	}
}

.email-left {		
	max-width: 640px;
}

.email-left-row {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 24px;
	img {
		flex: 0 0 200px;
		max-width: 200px;
	}	
}

.email-right {
	flex: 1 1 auto;
	background: #fff;
	padding: 32px;
	max-width: 640px;
	box-shadow: 0 0 40px rgba(0,0,0,.05);
	border-radius: 4px;
	min-width: 300px;
	p {
		font-weight: $bold;
		color: $color-dark;
	}
}


.email-right {
	
	.freeform-label {
		color: #fff;
		font-size: 1.6rem;
		line-height: calc(26/16);
		font-weight: bold;
		display: block;
		margin: 0 0 8px 0;
	}
	
	.freeform-row .freeform-column .freeform-label.freeform-required::after {
		color: #fff;
	}
	
	input.freeform-input {
		height: 48px;
		line-height: 48px;
		border-radius: 4px;
		background: #fff;
		padding: 0 16px;
		font-size: 1.6rem;
		font-family: $font;
		border: 1px solid transparent;
	}
	
	button {
		@extend .btn;
		@extend .btn-red;
		font-size: 2rem;
		line-height: calc(24/20);
		width: 100%;
		padding: 16px;
	}
	
	.freeform-row .freeform-column {
		padding-top: 16px;
		padding-bottom: 16px;
		position: relative;
	}
	
	.freeform-form-errors {
		border: 1px solid #fff !important;
		background: $color-blue !important;
		color: #fff !important;	
		padding: 16px 32px !important;
		margin: 0 0 24px 0;
	}
	
	.freeform-errors {
		width: auto;
		position: absolute;
		right: 15px;
		top: -0px;
		background: $color-blue;
		border-radius: 4px;
		padding: 8px 16px !important;
		margin: 0 !important;
		border: 1px solid #fff;
		z-index: $z_index-level3;
		li {
			padding: 0;
			margin: 0;
			color: #fff !important;
			font-size: 1.4rem;
			line-height: 1.5;
		}
		&:after {
			content: "";
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 10px 7px 0 7px;
			border-color: #fff transparent transparent transparent;
			position: absolute;
			right: 8px;
			top: 100%;
			z-index: $z_index-level1 + 1;
		}
		&:before {
			content: "";
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 10px 7px 0 7px;
			border-color: $color-blue transparent transparent transparent;
			position: absolute;
			right: 8px;
			top: calc(100% - 2px);
			z-index: $z_index-level1 + 2;
		}
	}
	
	.freeform-has-errors {
		position: relative;
		color: $color-red;
		&::placeholder {
			opacity: 1;
		}
	}
}

.email-right .freeform-button-container {
	padding-top: 16px;
	width: 100%;
	div,
	button {
		width: 100%;
		min-width: 100%;
	}
}