.apps {
	padding: 64px 0;
	margin: 0 0 64px 0;
	background: $color-teal;
	* {
		color: #fff;
	}
	@include respond(768px) {
		display: none;
	}
}

.apps-pretitle {
	font-size: 16px;
	line-height: 26px;
	font-weight: $semi;
	text-align: center;
	margin: 0 0 8px 0;
}

.apps .section-title-wrap {
	justify-content: center;
}

.apps-text {
	font-size: 1.8rem;
	line-height: calc(32/18);
	margin: 0 0 48px 0;
}

.apps-buttons {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 24px;
	flex-wrap: wrap;
	svg {
		display: block;
		height: 56px;
		width: auto;
	}
}