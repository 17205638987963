$bp-contact-stack: 660px;

.contactForm {
	padding: 0px 0 64px 0;
	@include respond($bp-padding) {
		padding: 0 0 120px 0;	
	}
	
	.container {
		max-width: 1136px;
	}
	
	.section-title {
		max-width: 580px;
		margin: 0 auto 48px auto;
		text-align: center;
	}
}

.contactForm-form {
	form {
		display: grid;
		gap: 32px;
		grid-template-columns: 1fr;
		@include respond($bp-contact-stack) {
			grid-template-columns: repeat(2, minmax(0, 1fr));		
			.freeform-row-1,
			.freeform-row-2,
			.freeform-row-3,
			.freeform-row-4 {
				grid-column: 1;			
			}
			
			.freeform-row-5,
			.freeform-row-6 {
				grid-column: 2;
			}
			.freeform-row-5 {			
				grid-row: 1 / span 3;
				.freeform-column,
				.freeform-column > div,
				textarea {
					height: 100%;
				}
			}
			.freeform-row-6 {
				align-self: end;
			}
		}
	}
	
	.freeform-row .freeform-column {
		padding-top: 0;
		padding-bottom: 0;
		position: relative;
		float: none;
	}	
	  
	.ff-errors {
		top: -16px;	
	}
	
}