$bp-articleStack: 768px;

.articleRelated {
	background: #F9F9F9;
	padding: 64px 0;
	@include respond($bp-padding) {
		padding: 120px 0;
	}
	
	.section-title {
		padding: 0 24px;
		text-align: center;
		margin: 0 0 48px 0;
	}
}

.articleRelated .container {
	max-width: 1032px;
	display: grid;
	grid-template-columns: 1fr;
	gap: 32px;
	
	@include respond($bp-articleStack) {
		grid-template-columns: repeat(3, minmax(0, 1fr));	
	}
	
	.article {
		grid-column: auto;
		
		@include respond($bp-articleStack) {
			display: block;
			background: transparent;
			box-shadow: none;
			.article-image {
				width: 100%;
				padding-top: calc((319 / 307) * 100%);
				height: 0;
				border-radius: 4px;
			}
		}
	}
	
	.article-image {
		overflow: hidden;
	}
	
	.article-inner {
		@include respond($bp-articleStack) {
			padding: 16px 0 0 0;
		}
	}
	.article-date {
		font-size: 1.2rem;
		line-height: calc(18/12);
		margin: 0 0 4px 0;
	}
	
	.article-title {
		font-size: 1.8rem;
		line-height: calc(24/18);
		font-weight: $medium;
		color: $color-dark;
		&:hover {
			color: $color-blue;
		}
	}
	
	.article-author {
		font-size: 1.4rem;
		line-height: 1.5;
		font-weight: $regular;
		margin: 4px 0 0 0;
	}
}
