.footer {
	background: #fff;
	padding: 64px 0;

	@include respond($bp-padding) {
		padding: 120px 0;
	}
}

.footer .container {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 24px 70px;
}

.footer .logo {
	flex: 0 0 192px;

	svg {
		max-width: 100%;
		height: auto;
	}
}

.footer-menus {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	// flex-wrap: wrap;
	gap: 24px;
	flex: 1 1 525px;
	max-width: 525px;

	@include respond(500px, "down") {
		flex-wrap: wrap;
	}
}

.footer-menu {
	list-style: none;
	margin: 0;
	padding: 0;
	flex: 1;

	@include respond(500px, "down") {
		flex: 1 0 100%;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-wrap: wrap;
		gap: 16px;
	}

	li {
		font-size: 1.4rem;
		line-height: calc(20/14);
		margin: 0 0 24px 0;
		font-weight: $bold;
		letter-spacing: -0.02em;
		padding: 0;

		@include respond(500px, "down") {
			margin-bottom: 0;
		}

		&:last-of-type {
			margin: 0;
		}

		a {
			text-decoration: none;
			color: #293446;

			&:hover {
				color: $color-teal;
			}
		}

	}
}


.footer-social-icons {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 32px;

	a {
		display: block;

		svg {
			width: 28px;
			height: 28px;
			display: block;
		}

		&:hover svg * {
			fill: darken($color-red, 10%);
		}
	}
}

.footer-social {
	p {
		margin: 26px 0 0 0;
		font-size: 1.4rem;
		line-height: calc(21/14);
		color: #293446;
	}
}