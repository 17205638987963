$bp-filterStack: 840px;

.table-headings {
	display: flex;
	margin-bottom: 35px;
	padding: 0 24px;
}

.heading {
	flex: 1;
	font-size: 2.2rem;
}

.showBlogs {
	padding: 104px 0 0px 0;

	@include respond($bp-padding) {
		padding: 120px 0 0 0;
	}

	.section-title {
		margin: 0 0 24px 0;

		@include respond($bp-padding) {
			margin: 0 0 64px 0;
			text-align: center;
		}
	}

}

.blogGrid {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 24px 32px;
	position: relative;
	z-index: 1;

	@include respond($bp-filterStack, "down") {
		flex-wrap: wrap;
	}

	&:after {
		content: "";
		background-image: url(../svg/gridEntries-bg.svg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center bottom;
		padding-top: calc((937 / 1228) * 100%);
		bottom: 0;
		left: 100px;
		right: 0;
		position: absolute;

		@include respond($bp-filterStack, "down") {
			left: 0;
		}
	}
}

.page-search .blogGrid {
	padding: 0 0 80px 0;

	@include respond($bp-padding) {
		padding: 0 0 120px 0;
	}
}

.blogGrid-sidebar {
	position: relative;
	z-index: 2;
	flex: 0 0 100%;

	@include respond($bp-filterStack) {
		flex: 0 0 300px;
	}
}

.blogGrid-entries {
	padding: 0 0 100px 0;
	overflow: hidden;
	flex: 1 1 auto;
	display: grid;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	gap: 64px 32px;
	position: relative;
	z-index: $z_index-level1 + 1;

	@include respond(1100px, "down") {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}

	@include respond($bp-filterStack, "down") {
		grid-template-columns: repeat(3, minmax(0, 1fr));
		padding: 0 0 64px 0;
	}

	@include respond(768px, "down") {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}

	@include respond(600px, "down") {
		grid-template-columns: 1fr;
		gap: 32px;
		padding: 0 0 32px 0;
	}

	&.onAirTabs {
		grid-template-columns: 1fr;
		gap: 24px;

		.blogGrid-entry {
			background-color: #fff;
			padding: 24px;
			box-shadow: 0 0 40px rgba(0, 0, 0, .05);
			display: block;
			border-radius: 4px;
			text-decoration: none;
			color: $color-dark;
			align-items: center;
			gap: 30px;
			display: flex;
			flex-direction: column;


			&:hover {

				.blog-entry-title {
					color: $color-blue;
				}

				.blogGrid-entry-title {
					color: $color-blue;
				}
			}

			@include respond(700px) {
				flex-direction: row;
			}

			// >div {
			// 	border: 1px solid red;
			// }
		}

		.blog-entry-speaker {
			// margin-bottom: 20px;
			// max-width: 250px;
			// text-align: center;
			flex: 1 1 200px;

			@include respond(700px) {
				flex: 0 1 15%;

			}

			.blogGrid-entry-image {
				padding-top: 100%;
				width: 250px;
				height: auto;

				@include respond(700px) {
					width: unset;
				}
			}
		}

		.blog-entry-title {
			flex: 1;

			@include respond(700px) {
				flex: 0 1 60%;
			}

			>* {
				margin-bottom: 10px;

				&:last-of-type {
					margin-bottom: 0;
				}
			}

		}

		.blogGrid-entry-times {
			flex: 1;
			display: inline-flex;
			// font-size: 1.8rem;

			@include respond(700px) {
				flex: 0 1 25%;
			}

		}

	}


}


.blogGrid-entry-image {
	display: block;
	padding-top: 100%;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	background-color: $color-blue;
	position: relative;
	overflow: hidden;
	border-radius: 4px;

	&:before {
		content: "";
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		position: absolute;
		background: linear-gradient(0deg, rgba(0, 127, 173, 0.9) 4.51%, rgba(0, 127, 173, 0) 34.67%);
		z-index: $z_index-level1;
	}
}

.blogGrid-entry-content {
	padding: 16px 0 0 0;
}

.blogGrid-entry-date {
	font-size: 1.2rem;
	line-height: calc(18/12);
	margin: 0 0 4px 0;
}

.blogGrid-entry-title {
	color: $color-dark;
	font-size: 1.6rem;
	line-height: calc(24/16);
	text-decoration: none;
	font-family: $font-accent;
	font-weight: $medium;
	margin: 0 0 4px 0;
	display: block;

	&:hover {
		color: $color-blue;
	}

	@include respond($bp-padding) {
		font-size: 1.8rem;
		line-height: calc(24/18);
	}
}

.blogGrid-entry-author {
	font-size: 1.4rem;
	line-height: calc(21/14);

	span {
		display: inline-block;
	}

	span:after {
		content: ", ";
	}

	span:last-of-type::after {
		content: "";
		display: none;
	}
}

.blogGrid-entry {
	@include respond(440px, "down") {
		display: flex;
		gap: 16px;

		.blogGrid-entry-image {
			width: 98px;
			height: 98px;
			padding: 0;
			flex: 0 0 98px;
		}

		.blogGrid-entry-content {
			flex: 1 1 auto;
			padding: 0;
		}
	}
}

.no-results {

	grid-column: 1 / span 3;

	@include respond(1100px, "down") {
		grid-column: 1 / span 2;
	}

	@include respond(840px, "down") {
		grid-column: 1 / span 3;
	}

	@include respond(640px, "down") {
		grid-column: 1 / span 2;
	}

	@include respond(440px, "down") {
		grid-column: 1 / span 1;
		padding: 24px;
	}



	h3 {
		color: $color-dark;
		line-height: 1.5;
		max-width: 320px;
		margin: 0 auto;
		background: #fff;
		box-shadow: 0 0 40px rgba(0, 0, 0, .05);
		border-radius: 4px;
		padding: 40px 24px;
		text-align: center;
		font-family: $font;
		font-weight: $bold;

	}
}