
.filters {
	
}

.filter-search {
	margin: 0 0 32px;	
}

.filter-active,
.filter-block {
	margin-bottom: 44px;
}

.filter-inner {
	@include respond($bp-filterStack) {
		display: block !important;
	}
	@include respond($bp-filterStack, "down") {
		display: none;
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background: #fff;
		z-index: $z_index-level6 + 1;
		
		
		padding: 40px 24px 104px 24px;
		
	}
	
	.filter-inner-wrap {
		max-height: calc(100vh - 144px);
		overflow: auto;	
	}
	
	.filter-apply {
		position: absolute;
		bottom: 24px;
		left: 24px;
		right: 24px;
		width: calc(100% - 48px);
		@include respond($bp-filterStack){
			display: none;
		}
	}
}

.filter-wrap-title {
	.section-title {
		position: relative;
	}
	
	.filter-close {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		width: 50px;
		height: 50px;
		border: none;
		cursor: pointer;
		background: none;
		padding: 0;
		svg {
			width: 50px;
			height: 50px;
			display: block;
		}
	}
	@include respond($bp-filterStack) {
		display: none;
	}
}

.filter-search {
	display: grid;
	grid-template-columns: 1fr 32px 32px;
	gap: 8px;
	
	@include respond($bp-filterStack) {
		grid-template-columns: 1fr 48px;	
	}
	
	button {
		background: transparent;
		padding: 0;
		cursor: pointer;
		border: none;
		svg {
			width: 32px;
			height: 32px;
			display: block;
			
			@include respond($bp-filterStack) {
				width: 48px;
				height: 48px;	
			}
		}
	}
	
	.filter-filters {
		@include respond($bp-filterStack) {
			display: none;
		}
	}
	input {
		height: 48px;
		line-height: 48px;
		border-radius: 4px;
		background: #fff;
		padding: 0 16px;
		font-size: 1.6rem;
		font-family: $font;
		border: 1px solid #808285;
		&:focus {
			border-color: $color-teal;
		}
	}
}

.filter-title {
	font-family: $font;
	font-weight: $bold;
	color: $color-dark;
	font-size: 1.8rem;
	line-height: calc(32/18);
	padding-bottom: 4px;
	margin: 0 0 24px 0;
	border-bottom: 1px solid $color-border;
}

.filter-block {
	select {
		width: 100%;
		height: 48px;
		line-height: 48px;
		border-radius: 4px;
		background: #fff;
		padding: 0 16px;
		font-size: 1.6rem;
		font-family: $font;
		appearance: none;
		-moz-appearance: none;
		-webkit-appearance: none;		
		border: 1px solid #808285;
		background-image: url(../svg/carat.svg);
		background-repeat: no-repeat;
		background-position: right 16px center;
		background-size: auto auto;	
		&:focus {
			border-color: $color-teal;
		}
	}
}

.filter-checkboxes {
	max-height: 224px;
	overflow: auto;
	display: grid;
	grid-template-columns: 1fr;
	gap: 16px;
	&.no-max {
		max-height: none;
	}
}

.filter-checkbox {
	overflow: hidden;
	position: relative;
	input {
		position: absolute;
		opacity: 0;
		left:-1px;
		top:-1px;
		height:1px;
		width:1px;
	}
	label {
		padding: 0 0 0 33px;
		line-height: calc(32/18);
		font-size: 1.8rem;
	}
}

.filter-checkbox-label:before {
	content:"";
	position: absolute;
	left:0;
	top:4px;
	width:24px;
	height:24px;
	background-image: url(../svg/checkbox.svg);
	background-repeat: no-repeat;
	background-size: 24px 24px;
	background-color: transparent;
}

.filter-checkbox input:checked + .filter-checkbox-label:before {
	background-image: url(../svg/checkbox-checked.svg);
}

.using-keyboard .filter-checkbox input:not(:checked):focus + .filter-checkbox-label:before {
	background-image: url(../svg/checkbox-focus.svg);
}

.filter-dateWrap {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 16px;
	#publishYear {
		flex: 0 0 calc(40% - 8px);
	}
	#publishMonth {
		flex: 0 0 calc(60% - 8px);	
	}
}


.filter-active {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;
	gap: 8px 16px;
}

.btn-filter {
	cursor: pointer;
	background: #fff;
	box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
	border-radius: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: $bold;
	flex: 0 0 auto;
	color: $color-dark;
	gap: 8px;
	padding: 8px 16px;
}


body.filters-open {
	.filter-inner {
		display: block;
	}
}
