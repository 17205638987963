.prayerWall {
	padding: 104px 0 0 0;
	@include respond($bp-padding) {
		padding: 176px 0 0 0;
	}

	@include respond($bp-contentStack) {
		background: radial-gradient(40% 100% at 0% 0%, rgba(0, 127, 173, 0.3) 0%, rgba(0, 127, 173, 0) 100%);
	}

	.section-title {
		margin-right: auto;
		font-weight: $medium;
		@include respond($bp-padding) {
			font-size: 3.2rem;
			line-height: calc(48/32);
		}
	}

	.container {
		max-width: 1138px;
	}
}

.prayer-block {
	background: #fff;
	box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
	border-radius: 4px;
	padding: 24px 32px;
	margin: 32px 0 0 0;
	@include respond($bp-padding) {
		padding: 32px 64px;
	}
}

.prayer-block-tag {
	background: #FFFFFF;
	border: 1px solid rgba(128, 130, 133, 0.05);
	box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
	border-radius: 4px;
	padding: 4px 8px;
	display: inline-flex;
	align-items: center;
	justify-content: flex-start;
	gap: 8px;
	font-weight: $bold;
	font-size: 1.4rem;
	line-height: 1.5;
	color: #808285;
	margin: 0 0 16px 0;
	svg {
		width: 24px;
		height: 24px;
		display: block;
	}
}

.prayer-block-content {
	color: $color-dark;
	font-size: 1.6rem;
	line-height: calc(24/16);
	margin: 0 0 40px 0;
	@include respond($bp-padding) {
		font-size: 1.8rem;
		line-height: calc(32/18);
	}
}

.prayer-block-content p {
	font-size: 1.6rem;
	line-height: calc(24/16);
	@include respond($bp-padding) {
		font-size: 1.8rem;
		line-height: calc(32/18);
	}
}

.prayer-block-meta {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 40px 32px;
	& > div {
		flex: 0 0 auto;
	}
}

.prayer-block-meta-left {
	font-size: 1.6rem;
	line-height: calc(26/16);
	strong {
		font-size: 1.8rem;
		line-height: calc(32/18);
		display: block;
	}
}

.prayer-block-meta-right {
	text-align: right;
	.btn {
		width: 160px;
		text-align: center;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
		font-size: 1.6rem;
		line-height: 1.5;
		padding: 16px 8px;
		svg {
			width: 20px;
			height: 20px;
			display: block;
		}
	}
	.prayer-block-meta-right-done {
		font-size: 1.8rem;
		line-height: calc(32/18);
		color: $color-green;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 8px;
		font-weight: $bold;
		&.text-white {
			color: $color-white;
			svg path {
				stroke: $color-white;
			}
		}
		svg {
			width: 24px;
			height: 24px;
			display: block;
		}
	}
	.prayer-block-meta-right-stats {
		font-size: 1.4rem;
		line-height: 1.5;
		margin: 8px 0 0 0;
	}
}

.prayer-block-report {
	border-top: 1px solid rgba(205, 205, 205, 0.6);
	margin: 24px 0 0 0;
	padding: 16px 0 0 0;
	text-align: center;
	font-size: 1.4rem;
	line-height: 1.5;
}

.prayer-block-report-button {
	padding: 0;
	background: transparent;
	cursor: pointer;
	color: $color-teal;
	text-decoration: none;
	font-weight: $bold;
	display: inline-block;
	&:hover {
		text-decoration: underline;
	}
}




.button-toggle {
	display: inline-flex;
	align-items: stretch;
	justify-content: center;
	margin-left: auto;
	input {
		position: absolute;
		opacity: 0;
		left:-1px;
		top:-1px;
		height:1px;
		width:1px;
	}
	label {
		background: #fff;
		color: $color-teal;
		border: 1px solid $color-teal;
		display: flex;
		gap: 8px;
		cursor: pointer;
		padding: 8px 16px;
		align-items: center;
		justify-content: center;
		font-weight: $bold;
		font-size: 1.4rem;
		line-height: 1.5;
		transition: color 0.4s ease, background 0.4s ease;
		border-radius: 4px 0 0 4px;
		svg * {
			transition: fill 0.4s ease;
		}

		&:last-child {
			border-radius: 0 4px 4px 0;
		}
	}


	input:checked + label {
		background: $color-teal;
		color: #fff;
		svg * {
			fill: #fff;
		}
	}
}

.prayerWall .pagination {
	margin-top: 32px;
}



// -----------------------------------------------------------------------------
//! Modal
// -----------------------------------------------------------------------------

.report-preview {
	background: rgba(128, 130, 133, 0.05);
	border-radius: 4px;
	padding: 21px 24px;
	.report-preview-inner {
		height: 52px;
		overflow: hidden;
	}
	p {
		font-size: 1.6rem;
		line-height: 26px;
	}
}
