$bp-herostack: 860px;

.pageTitle {
	position: relative;
	@include respond($bp-herostack) {
		padding: 172px 0; 
	}
	
	.container {
		z-index: $z_index-level1 + 2;
		position: relative;
		@include respond($bp-herostack, "down") {
			padding-top: 24px;
			padding-bottom: 24px;
		}
	}
}



.pageTitle-spacer {
	background-color: $color-blue;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: right center;
	padding-top: calc((280 / 376) * 100%);
	position: relative;
	@include respond(600px) {
		padding-top: calc((300 / 600) * 100%);
	}
	@include respond($bp-herostack) {
		padding: 0;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		z-index: $z_index-level1;
	}	
}

.pageTitle-spacer:before {
	content: "";
	position: absolute;
	left: 0;
	bottom: 0;
	top: 0;
	right: 0;
	background: radial-gradient(39.17% 142.11% at 6.32% 39.5%, rgba(0, 127, 173, 0.94178) 0%, rgba(0, 127, 173, 0) 100%);
	z-index: $z_index-level1 + 1;
}




.pageTitle-pretitle {
	font-family: $font-accent;
	font-weight: $medium;
	letter-spacing: -0.02em;
	font-size: 2rem;
	line-height: calc(34/20);
	color: $color-dark;
	@include respond($bp-herostack) {
		color: #fff;
	}	
}

.pageTitle-title {	
	color: $color-blue;
	font-weight: $bold;
	font-size: 2.6rem;
	line-height: calc(34/26);
	margin: 0;
	max-width: 480px;
	@include respond($bp-herostack) {
		color: #fff;
		font-size: 4.2rem;
		line-height: calc(56/42);		
	}
}

.page-christian-schools .pageTitle-title,
.page-jobs .pageTitle-title {
	max-width: 600px;
}

.pageTitle-content {
	margin: 8px 0 0 0;
	color: $color-body;
	max-width: 526px;
	font-size: 1.6rem;
	line-height: calc(26/16);
	@include respond($bp-herostack) {
		color: #fff;
		font-size: 1.8rem;
		line-height: calc(32/18);
	}
}

.pageTitle .btn {
	margin: 32px 0 0 0;
}

.pageTitle-announcement {
	background: #FFFFFF;	
	box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
	margin: 0 auto;
	max-width: 1368px;
	width: calc(100% - 48px);	
	padding: 20px 24px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 8px;
	@include respond($bp-herostack) {
		gap: 16px;
		width: 100%;	
		flex-direction: row;
		align-items: center;
		padding: 24px;
		margin: 0;
		position: absolute;
		top: 100%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: $z_index-level1 + 1;
	}
	svg {
		width: 24px;
		height: 24px;
		@include respond($bp-herostack) {
			width: 48px;
			height: 48px;
		}
	}
	p {
		font-weight: $bold;
		margin: 0;
		font-size: 1.4rem;
		line-height: calc(21/14);
		color: $color-blue;
		@include respond($bp-herostack) {
			font-size: 1.8rem;
			line-height: calc(32/18);	
		}
	}
	
	&.is-flex {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 16px;
		flex-wrap: wrap;
		.btn {
			margin: 0;
			font-size: 2rem;
			line-height: calc(24/20);
			padding: 15px 32px;
		}
	}
}

.pageTitle-announcement-title {
	font-family: $font-accent;
	font-weight: $medium;
	font-size: 2.2rem;
	line-height: calc(32/22);	
	letter-spacing: -0.02em;
	margin: 0;
}

.pageTitle-sponsor {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
	gap:16px;
	font-size: 1.6rem;
	line-height: calc(26/16);
	color: #fff;
	font-weight: $bold;
	@include respond($bp-herostack, "down") {
		color: $color-body;
	}
}

.pageTitle-sponsor img {
	@include respond($bp-herostack) {
		filter: brightness(0) invert(1);
	}
}

// -----------------------------------------------------------------------------
//! Page Specific
// -----------------------------------------------------------------------------

.page-shows .pageTitle {
	@include respond($bp-herostack) {
		padding: 224px 0; 
	}
	
	.pageTitle-spacer {
		background-position: center center;
		&:before {
			@include respond(400px, "down") {
				left: -250px;
			}
		}
	}
	
	.container {
		
		@include respond($bp-herostack, "down") {
			padding-bottom: 0;
		}
	}
}


.page-on-air .pageTitle {
	@include respond($bp-herostack) {
		padding: 224px 0; 
	}
	
}

.page-search {
	.pageTitle-spacer {
		background-image: url(../webp/search-hero.webp);
	}
	
	.hero-wrap .container {
		max-width: 640px;
		form {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 10px;
			input {
				flex: 1 1 calc(100% - 32px - 10px - 32px - 10px);
				@include respond($bp-filterStack) {
					flex: 1 1 calc(100% - 48px - 10px);
				}
			}
			button {
				flex: 0 0 32px;
				padding: 0;
				background: transparent;
				@include respond($bp-filterStack) {
					flex: 0 0 48px;
					&.filters-toggle {
						display: none;
					}
				}
				svg {
					width: 32px;
					height: 32px;
					
					@include respond($bp-filterStack) {
						width: 48px;
						height: 48px;
					}
				}
			}
		}
	}
}

.article-pageTitle.pageTitle {
	@include respond($bp-herostack) {
		padding: 0;
		height: 400px;
	}
}

.page-events .hero-wrap {
	@include respond($bp-herostack, "down") {
		padding-bottom: 16px;
	}
}

.pageTitle-button-wrap {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 24px;
	margin: 32px 0 0 0;
	@include respond(500px, "down") {
		flex-direction: column;
		align-items: flex-start;
	}
	.btn {
		margin: 0;
	}
	
	.pageTitle-button-wrap-divider {
		height: 68px;
		width: 1px;
		background: #fff;
		@include respond($bp-herostack, "down") {
			background: $color-border;	
		}
		@include respond(500px, "down") {
			width: 224px;
			height: 1px;	
		}
	}
	
	.pageTitle-hd2-play {
		padding: 0;
		background: transparent;
		border: none;
		display: inline-flex;
		align-items: center;
		justify-content: flex-start;
		gap: 8px;
		font-size: 24px;
		line-height: 1;
		color: #fff;
		font-family: $font;
		font-weight: 700;
		svg {
			width: 32px;
			height: 32px;
		}
		
		@include respond($bp-herostack, "down") {
			color: $color-blue;
			svg * {
				fill: $color-blue;
			}
		}
	}
}

//Special 

.pageTitle-spacer-digital-mob {
	@include respond($bp-herostack, "down") {
		background-position: -70px center !important;
	}
}