.podcasts {
	padding: 64px 0;
	@include respond($bp-padding) {
		padding: 120px 0;
	}
}

.podcasts .section-title {
	margin: 0 0 32px 0;
	@include respond($bp-padding) {
		margin: 0 0 48px 0;
	}
}

.podcasts-grid {
	display: grid;
	gap:  32px;
	grid-template-columns: 1fr;
	@include respond(520px) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
		gap: 48px 32px;
	}
	@include respond(768px) {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
}

.podcasts-box-image {
	padding-top: 100%;
	position: relative;
	background-color: $color-blue;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
	&:after {
		content: "";
		width: 32px;
		height: 32px;
		position: absolute;
		top: 20px;
		right: 20px;
		background-image: url(../svg/info-circle.svg);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		z-index: $z_index-level1;
		@include respond(600px) {
			display: none;
		}
	}
}

.podcasts-box-image-wrap {
	position: absolute;
	z-index: $z_index-level1 + 1;
	top:0;
	left:0;
	bottom: 0;
	right: 0;
	padding: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #fff;
	opacity: 0;
	transition: opacity 0.4s ease;
}

.podcasts-box-desc {
	max-height: 100%;
	overflow: auto;
	font-size: 1.6rem;
	line-height: calc(26/16);
}

.podcasts-box-image:hover .podcasts-box-image-wrap {
	opacity: 1;
}

.podcasts-box-title {
	display: block;
	font-family: $font-accent;
	font-weight: $medium;
	font-size: 1.8rem;
	line-height: calc(24/18);
	color: $color-body;
	text-decoration: none;
	margin: 24px 0 0 0;
	transition: color 0.4s ease;
	@include respond($bp-padding) {
		letter-spacing: -0.02em;
		font-size: 2.8rem;
		line-height: calc(40/28);
	}
	&:hover {
		color: $color-teal;
	}
}

//Expander-deal.

.podcasts-box {
	display: none;
	position: relative;
	&.no-after {
		display: block;
	}
	&:nth-child(1),
	&:nth-child(2),
	&:nth-child(3) {
		display: block !important;
	}
	@include respond(768px) {
		
		&:nth-child(4),
		&:nth-child(5),
		&:nth-child(6) {
			display: block !important;
		}
	}
	
	&:nth-child(4),
	&:nth-child(5),
	&:nth-child(6) {
		height: 200px;
		overflow: hidden;
		pointer-events: none;
		&:after {
			content: "";
			background: linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 391.93%);
			position: absolute;
			left: 0;
			bottom: 0;
			right: 0;
			top: 0;
			transition: opacity 0.2s ease;
		}
		&.no-after{
			height: auto;
			overflow: initial;
			pointer-events: auto;
			&:after {
				opacity: 0;
				pointer-events: none;
			}
		}
	}
	@include respond(768px, "down") {
		&:nth-child(4),
		&:nth-child(3) {
			height: 200px;
			overflow: hidden;
			pointer-events: none;
			&:after {
				content: "";
				background: linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 391.93%);
				position: absolute;
				left: 0;
				bottom: 0;
				right: 0;
				top: 0;
				transition: opacity 0.2s ease;
			}
			&.no-after{
				height: auto;
				overflow: initial;
				pointer-events: auto;
				&:after {
					opacity: 0;
				}
			}
		}
		
		&:nth-child(5),
		&:nth-child(6) {
			display: none;	
		}
	}
	
	@include respond(520px) {
		&:nth-child(4) {
			display: block !important;	
		}
	}
	
	
		
	
	@include respond(520px, "down") {
	
		
		&:nth-child(4) {
			display: none;	
		}
		
	}
	
}


.podcasts-grid-control {
	text-align: center;
	padding: 48px 0 0 0;
}


// -----------------------------------------------------------------------------
//! Individual Podcast Pages
// -----------------------------------------------------------------------------


.podcastContent {
	
	background-image: url(../webp/bottom-right-blur.webp);
	background-size: auto;
	background-repeat: no-repeat;
	background-position: right bottom; 
	padding: 64px 0;
	@include respond($bp-padding) {
		padding: 160px 0;
	}
	
	.container {
		max-width: 1024px;
		&:after {
		  content: "";
		  display: table;
		  clear: both;
		}
	}
	
	.spreaker {
		max-width: 1024px;
		padding: 0 24px;
		margin: 40px auto 0 auto;
	}
	
	.articleContent-share {
		padding-bottom: 0;
	}
}

.podcastCoverImage {
	margin: 0 0 32px 0;
	@include respond(464px) {
		max-width: 50%;
		float: left;
		margin: 0 32px 32px 0;
	}
}