$bp-filterStack: 840px;

.eventsWrap {
	padding: 0;
	@include respond(860px) {
		padding: 104px 0 0px 0;
	}
	@include respond($bp-padding) {
		padding: 120px 0 0 0;	
	}
	
}

.eventsGrid {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 24px 32px;
	position: relative;
	@include respond($bp-filterStack, "down") {
		flex-wrap: wrap;	
	}
}


.eventsGrid-entries {
	width: 100%;
}

.eventGrid-entry {
	display: flex;
	align-items: stretch;
	justify-content: flex-start;	
	gap: 4px;
	margin: 0 0 64px 0;
	@include respond(480px) {
		gap: 16px;
	}
	@include respond($bp-filterStack) {
		gap: 32px;	
	}
}


.eventGrid-entry-left {
	flex: 0 0 90px;
	text-align: center;
	position: relative;
	
	@include respond(600px, "down") {
		flex: 0 0 70px;	
	}
	
	&:after {
		content: "";
		position: absolute;
		background: $color-border;
		width: 1px;
		left: 50%;
		top: 0;
		bottom: 0;
		transform: translateX(-50%);
		z-index: $z_index-level1;
	}
}

.eventGrid-entry-right {
	flex: 1 1 auto;
}

.eventGrid-entry-left-date {
	background: #fff;
	padding-bottom: 8px;
	z-index: $z_index-level1 + 1;
	position: relative;
}

.eventGrid-entry-left-to {
	background: #fff;
	padding: 8px 0 16px 0;
	z-index: $z_index-level1 + 1;
	position: relative;
	font-size: 1.2rem;
	line-height: calc(18/12);
	text-transform: lowercase;
	color: $color-dark;
}

.eventGrid-entry-left-date-day {
	font-size: 1.2rem;
	line-height: calc(18/12);
	letter-spacing: 0.05em;
	text-transform: uppercase;
	color: $color-blue;
	@include respond(600px, "down") {
		font-size: 1rem;	
	}
}

.eventGrid-entry-left-date-month,
.eventGrid-entry-left-date-num {
	margin: 4px 0 0 0;
	color: $color-blue;
	font-size: 2.4rem;
	line-height: 1;
	font-weight: $medium;
	font-family: $font-accent;
	@include respond(600px, "down") {
		font-size: 1.8rem;
		line-height: calc(24/18);
	}
}

.eventGrid-entry-type {
	background: #fff;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	padding: 4px 8px;
	border-radius: 4px;
	border: 1px solid rgba(128, 130, 133, 0.05);	
	box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
	font-weight: $bold;
	font-size: 1.4rem;
	line-height: 1.5;
	color: #808285;
	img  {
		flex: 0 0 24px;
	}
}

.event-entry-title {
	font-size: 1.8rem;
	line-height: calc(24/18);
	color: $color-blue;
	font-family: $font-accent;
	font-weight: $medium;
	text-decoration: none;
	display: block;
	margin: 24px 0 32px 0;
	@include respond($bp-padding) {
		font-size: 2.2rem;
		line-height: calc(32/22);
	}
}

.eventGrid-entry-content {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 32px;
	@include respond($bp-padding, "down") {
		flex-wrap: wrap;
	}
}

.eventGrid-entry-description {
	flex: 1 1 410px;
	max-width: 410px;	
}

.eventGrid-entry-meta {
	flex: 1 1 370px;
	max-width: 370px;
	@include respond(480px, "down") {
		flex: 0 0 100%;
	}
}

.eventGrid-entry-meta-row {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 12px;
	img, svg {
		flex: 0 0 24px;
	}
}

.eventGrid-entry-meta-row-text {
	font-weight: $bold;
	font-size: 1.4rem;
	line-height: calc(21/14);
	margin: 0 0 24px 0;
	@include respond($bp-padding) {
		font-size: 1.6rem;
		line-height: calc(26/16);		
	}
	
	span:after {
		content: ", ";
	}
	span:last-of-type:after{
		content: "";
	}
}


.eventGrid-entry.is-featured {
	position: relative;
	background: #F9FBFE;
	border: 1px solid $color-blue;
	border-radius: 4px;
	box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
	padding: 64px 10px 32px 10px;
	@include respond(480px) {
		padding: 64px 24px 32px 24px;
	}
	@include respond($bp-padding) {
		padding: 64px 32px;
	}
	
	.eventGrid-entry-left-date,
	.eventGrid-entry-left-to {
		background: #f9fbfe;
	}
	&.has-filters {
		display: none !important;
	}
}

.is-featured-tag {
	position: absolute;
	top: 0;
	right: 0;
	background: $color-blue;
	font-size: 1.6rem;
	line-height: calc(26/16);
	padding: 12px;
	display: inline-flex;
	align-items: center;
	justify-content: flex-start;
	gap: 10px;
	color: #fff;
	font-weight: $bold;
	svg {
		flex: 0 0 24px;
	}
}


// -----------------------------------------------------------------------------
//! Single Events
// -----------------------------------------------------------------------------

.events-content {
	background-image: url(../webp/form-blur.webp);
	background-position: left bottom;
	background-repeat: no-repeat;
	background-size: auto;
	padding: 24px 0 32px 0;
	@include respond($bp-padding) {
		padding: 80px 0 64px 0;
	}	
	.container {
		max-width: 700px;
	}
	
	.articleInfo-title {
		margin-bottom: 64px;
	}

	.event-meta {
		background: #FFFFFF;
		box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
		border-radius: 4px;
		padding: 32px 16px;
		margin: 0 0 64px 0;
		@include respond(600px) {
			padding: 40px 24px;	
		}
	}
	
	.event-meta-grid {
		display: grid;
		gap: 24px 16px;
		grid-template-columns: 1fr;
		padding: 0 0 24px 0;
		margin: 0 0 24px 0;
		border-bottom: 1px solid rgba(205, 205, 205, 0.6);
		@include respond(600px) {
			grid-template-columns: repeat(2, minmax(0, 1fr));	
		}
	}
	
	.eventGrid-entry-meta-row-text {
		margin: 0;
	}
	
	.event-meta-grid-item {
		display: flex;
		gap: 8px;
		svg {
			flex: 0 0 auto;
		}
		
		a {
			text-decoration: none;
			svg * {
				fill: $color-teal;
			}
		}
	}
	
	.event-meta-label {
		font-weight: $bold;
		font-size: 1.2rem;
		line-height: cal(18/12);		
		letter-spacing: 0.05em;
		text-transform: uppercase;
	}

}


.event-calendar-buttons {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
	gap: 8px;
	margin: 16px 0 0 0;
}

// -----------------------------------------------------------------------------
//! Hero Images
// -----------------------------------------------------------------------------



.event-background-camp {
	background-image: url('../webp/event-hero-camp.webp');
}

.event-background-classes {
	background-image: url('../webp/event-hero-class.webp');
}

.event-background-concerts {
	background-image: url('../webp/event-hero-concerts.webp');
}

.event-background-conferences {
	background-image: url('../webp/event-hero-conference.webp');
}

.event-background-family-fun {
	background-image: url('../webp/event-hero-family-fun.webp');
}

.event-background-job-fairs {
	background-image: url('../webp/event-hero-job-fairs.webp');
}

.event-background-other-events {
	background-image: url('../webp/event-hero-other.webp');
}

.event-background-seasonal-events {
	background-image: url('../webp/event-hero-seasonal-events.webp');
}

.event-background-theater-movie-events {
	background-image: url('../webp/event-hero-theater-movie-event.webp');
}