.page-christian-schools .pageTitle .btn {
	margin: 0;
}

.page-christian-schools .pageTitle .pageTitle-announcement {
	justify-content: space-between;
	gap: 16px 32px;
}
.page-christian-schools .pageTitle .school-announcement {
	font-family: $font-accent;
	font-weight: $medium;	
	letter-spacing: -0.02em;
	font-size: 18px;
	line-height: 24px;
	@include respond($bp-padding) {
		font-size: 22px;
		line-height: 32px;	
	}
}

.schoolBlurb {
	padding: 64px 24px;
	@include respond($bp-padding) {
		padding: 120px 24px;
	}
	
	.schoolBlurb-title {
		max-width: 1304px;
		margin: 0 auto;
		font-family: $font-accent;
		font-weight: $medium;
		font-size: 18px;
		line-height: 24px;
		@include respond($bp-padding) {
			font-size: 20px;
			line-height: 32px;	
		}
	}
}

.schoolGrid {
	padding: 0 24px;
	display: grid;
	gap: 32px 42px;
	margin: 0 auto;
	grid-template-columns: repeat(auto-fill, minmax(227px, 1fr));
	max-width: 1350px;
}

.schoolGrid-box {
	text-decoration: none;
	display: block;
}

.schoolGrid-box-image {
	padding-top: calc((170 / 294) * 100%);
	border-radius: 4px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	position: relative;
	overflow: hidden;
	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background: linear-gradient(360deg, rgba(0, 127, 173, 0.9) -16.15%, rgba(0, 127, 173, 0) 59.17%);
		transition: opacity 0.4s ease;
	}
	
}

.schoolGrid-box-title {
	font-family: $font-accent;
	font-weight: $medium;
	font-size: 18px;
	line-height: 24px;
	text-decoration: none;
	color: $color-dark;
	transform: color 0.4s ease;
	margin: 16px 0 0 0;
	@include respond($bp-padding) {
		font-size: 20px;
		line-height: 32px;	
	}
}

.schoolGrid-box:hover {
	.schoolGrid-box-title {
		color: $color-blue;
	}
	.schoolGrid-box-image:after {
		opacity: 0;
	}
}


.schoolMap {
	padding: 64px 0 0 0;
	@include respond($bp-padding) {
		padding: 120px 0 0 0;
	}
}

.schoolMap .section-title {
	text-align: center;
	margin: 0 0 32px 0;
	@include respond($bp-padding) {
		margin: 0 0 64px 0;
	}
}

.map {
	height: 736px;
	background: #ccc;
	width: 100%;
}

// -----------------------------------------------------------------------------
//! Ind. School Pages
// -----------------------------------------------------------------------------
.schoolContent {
	position: relative;
	background-image: url(../webp/bottom-right-blur.webp);
	background-size: auto;
	background-repeat: no-repeat;
	background-position: right bottom;
	padding: 0 0 80px 0;
	@include respond($bp-padding) {
		padding: 120px 0;
	}
	.container {
		max-width: 688px;
		position: relative;
		z-index: $z_index-level1 + 1;
	}
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: $z_index-level1;
		@include respond(860px) {
			background-image: url(../webp/top-left-blur.webp);
			background-position: left top;
			background-size: auto;
			background-repeat: no-repeat;
		}
	}
}

.schoolMeta {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin: 32px 0;
	gap: 32px;
	flex-wrap: wrap;
	.schoolMeta-info {
		flex: 0 0 auto;
	}
	.schoolMeta-logo {
		flex: 0 0 auto;		
		max-width: calc(50% - 32px);
	}
	
	.eventGrid-entry-meta-row {
		font-size: 16px;
		line-height: 26px;
		font-weight: $bold;
		margin: 0 0 24px 0;
		a {
			text-decoration: none;
		}
	}
}

.schoolMeta-return {
	text-align: center;
}

// -----------------------------------------------------------------------------
//! Markers
// -----------------------------------------------------------------------------

.marker-title {
	font-family: $font;
	font-weight: $bold;
	font-size: 18px;
	line-height: 32px;
	color: $color-dark;
	margin: 0;
}

.marker-address {
	margin: 0 0 8px 0;
	color: $color-dark;
	font-size: 14px;
	line-height: 21px;
}

.marker-inner {
	padding: 12px;
	text-align: center;
}

.gm-ui-hover-effect {
	top: 0 !important;
	right: 0 !important;
}

.gm-style .gm-style-iw-c {
	border-radius: 4px !important;
}