.showTop {
	text-align: center;
	padding: 0px 0 48px 0;
	@include respond($bp-contentStack) {
		padding: 64px 0 48px;	
	}
	@include respond($bp-padding) {
		padding: 120px 0 80px;	
	}
	
	@include respond($bp-contentStack) {
		background: radial-gradient(40% 100% at 0% 0%, rgba(0, 127, 173, 0.3) 0%, rgba(0, 127, 173, 0) 100%);
	}
}


.airTimes {
	background: #fff;
	box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
	border-radius: 4px;
	padding: 40px 24px;
	display: inline-flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;
	gap: 32px 40px;
	@include respond(420px) {
		padding: 40px 64px;
	}
	@include respond(600px) {
		justify-content: center;		
	}
}

.airTimes-inner {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;
	gap: 8px 40px;
	@include respond(600px) {
		justify-content: center;		
	}
}

.airTimes-title {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 10px;
	font-size: 1.8rem;
	line-height: calc(24/18);
	font-weight: 500;
	font-family: $font-accent;
	color: $color-blue;
	svg {
		flex: 0 0 19px;
	}
}

.airTimes-block {
	display: flex;
	align-items: flex-start;
	gap: 4px;
	justify-content: flex-start;
	
	@include respond($bp-padding) {
		padding: 3px 40px 0 0;
		border-right: 1px solid $color-dark;
		&:last-of-type {
			padding-right: 0;
			border-right: 0;
		}
	}
}