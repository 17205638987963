$bp-rform: 800px;

.resourcesWrap {
	padding: 0 0 40px 0;
	@include respond(860px) {
		padding: 104px 0 120px 0;
	}
	@include respond($bp-padding) {
		padding: 120px 0;	
	}
	
	.section-title {
		text-align: center;
		margin: 0 0 32px 0;
		@include respond($bp-padding) {
			margin: 0 0 96px 0;
		}
	}
}


.resourcesGrid-entries {
	@include respond(550px, "down") {
		flex: 0 0 100%;
	}
}

.resourceGrid-entries-grid {
	margin: 0 0 40px 0;	
	width: 100%;
	@include respond(550px) {
		display: grid;
		gap: 32px 16px;	
		grid-template-columns: repeat(2, minmax(0, 1fr));	
	}
	@include respond(740px) {
		grid-template-columns: repeat(3, minmax(0, 1fr));	
	}
	@include respond(840px) {
		grid-template-columns: 1fr;	
	}
	@include respond(900px) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
	@include respond(1180px) {
		grid-template-columns: repeat(3, minmax(0, 1fr));	
	}
	@include respond(1260px) {
		gap: 64px 32px;
	}
}


.resource-entry-block {
	display: block;
	text-decoration: none;
	position: relative;
	padding: 24px 24px 104px 24px;
	background: #fff;
	box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
	
	.btn {
		position: absolute;
		left: 24px;
		bottom: 24px;
		@include respond(840px, "down") {
			padding-left: 16px;
			padding-right: 16px;
			right: 24px;
		}
	}
	@include respond(550px, "down") {
		margin: 0 auto 32px auto;	
		max-width: 358px;
	}
}

.resource-entry-title {
	font-family: $font-accent;
	font-size: 1.6rem;
	line-height: calc(24/16);	
	letter-spacing: -0.02em;	
	color: $color-dark;
	margin: 24px 0 0 0;
	@include respond($bp-padding) {
		font-size: 2rem;
		line-height: calc(32/20);		
	}
}

.resource-entry-image {
	padding-top: calc((380 / 310) * 100%);
	background: #fff;
 	background-repeat: no-repeat;
	background-position: center center;
	background-image: url(../svg/logo.svg);
	background-size: contain;
	position: relative;
}

.resource-entry-blurb {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #fff;
	opacity: 0;
	overflow: auto;
	transition: opacity 0.4s ease;
}

.resource-entry-blurb-inner {
	height: 100%;
	color: $color-dark;
	font-size: 1.6rem;
	line-height: 1.5;
}

.resource-entry-block:hover .resource-entry-blurb {	
	opacity: 1;
}

// -----------------------------------------------------------------------------
//! Single
// -----------------------------------------------------------------------------

.rMain {
	padding: 8px 0 82px 0;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center top;
	background-image: url(../webp/resource-bg.webp);
	@include respond($bp-rform) {
		padding: 80px 0 82px 0;	
	}
	& > .container {
		max-width: 1248px;
		@include respond($bp-rform) {
			gap: 40px;
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-template-rows: 430px 1fr;
		}
	}
}

.rMain-form-wrap {
	grid-row: 1 / span 2;
	grid-column: 2;
	max-width: 532px;
	padding: 0 0 0 0;
	@include respond($bp-rform, "down") {
		margin: 40px 0;	
		max-width: none;
	}

}
.rMain-form {
	
	background: #fff;
	box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
	
	padding: 48px;
	@include respond(420px, "down") {
		padding: 24px;
	}
	.rMain-title {
		margin: 0 0 22px 0;
	}
	
	@include respond($bp-rform) {
		transform: translateY(80px);
		position: sticky;
		top: 0;
		left: 0;
	}
	
}

.rMain-text {
	
	grid-row: 2;
	grid-column: 1;
	@include respond($bp-rform) {
		max-width: 532px;	
		padding-top: 80px;	
	}
}

.rMain-image-wrap {
	grid-column: 1;
	grid-row: 1;
	padding-top: 84px;
	@include respond($bp-rform) {
		padding-top: 80px;	
	}
}
.rMain-image {
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
	padding-top: calc((387/528) * 100%);
	height: 100%;
}

.rMain-title {
	font-weight: $regular;
	font-size: 2.4rem;
	line-height: calc(32/24);
	letter-spacing: -0.02em;
	margin: 32px 0;
	@include respond($bp-padding) {
		font-size: 3.2rem;
		line-height: calc(42/32);	
	}
}