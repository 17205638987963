.listenTop {
	background: radial-gradient(40% 100% at 0% 0%, rgba(0, 127, 173, 0.3) 0%, rgba(0, 127, 173, 0) 100%);
	padding: 112px 0 24px 0;

	@include respond($bp-padding) {
		padding: 112px 0 64px 0;
	}
}

.listenTop .container {
	display: grid;
	grid-template-columns: 1fr;
	gap: 32px;
	@include respond(720px) {
		gap: 48px;
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
}

.listenTop-left {
	.section-title {
		margin: 0 0 32px 0;
	}
}

.listenTop-left-wrap {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;
	gap: 32px;
}

.listenTop-left-current {
	flex: 0 1 200px;
	@include respond(370px, "down") {
		flex: 0 0 100%;
		max-width: 200px;
	}
	.recent-box-album {
		display: block;
		margin: 0;
		position: relative;
		img {
			display: block;
			max-width: 100%;
		}
		&:before {
			bottom: 16px;
			right: 16px;
			width: 56px;
			height: 56px;
		}
		.play-pause-button {
			position: absolute;
			right: 1rem;
			bottom: 1rem;
			z-index: $z-index-level1 + 2;
		}
	}
}

.listenTop-left-info {
	flex: 0 0 auto;
	padding: 16px 0 0 0;
	max-width: 100%;
	@include respond(500px) {
		width: 100%;
		max-width: calc(100% - 232px);
	}
}

.play-pause-button,
.back-to-radio-button {
	background: none;
	padding: 0.5rem;
	line-height: 0;
	svg {
		* {
			fill: #fff;
		}
	}
}

.current-artist {
	font-weight: $bold;
	font-size: 1.4rem;
	line-height: calc(21/14);
	text-transform: uppercase;
	@include respond($bp-padding) {
		font-weight: $bold;
		font-size: 1.6rem;
		line-height: calc(26/16);
	}
}

.current-song {
	font-family: $font-accent;
	font-weight: $regular;
	font-size: 2.2rem;
	line-height: calc(32/22);
	letter-spacing: -0.02em;
	margin: 0 0 16px 0;
	@include respond($bp-padding) {
		font-weight: $medium;
		font-size: 3.6rem;
		line-height: calc(48/36);
	}

}

.current-share {
	font-weight: $bold;
	font-size: 1.6rem;
	line-height: calc(26/16);
	background: none;
	cursor: pointer;
	padding: 0;
	display: inline-flex;
	justify-content: flex-start;
	align-items: center;
	gap: 8px;
	color: $color-teal;
	svg {
		display: block;
		width: 18px;
		height: 18px;
	}

	@include respond($bp-padding) {
		font-size: 1.8rem;
		line-height: calc(32/18);
	}
}
.current-share-panel {
	display: none;
}
.current-share-panel-inner {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 20px;
	padding-top: 16px;
	a {
		display: block;
		text-decoration: none;
		svg {
			width: 32px;
			height: 32px;
			* {
				fill: $color-dark;
			}
		}
		&:hover svg * {
			fill: $color-teal;
		}
	}
}

.current-next {
	margin: 24px 0 0 0;
	padding: 24px;
	box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
	border-radius: 4px;
	background: #fff;
}

.current-next-title {
	text-transform: uppercase;
	font-weight: $bold;
	font-size: 1.6rem;
	color: $color-teal;
	line-height: calc(26/16);
	@include respond($bp-padding) {

	}
}

.current-next-info {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 8px;
	font-size: 1.4rem;
	line-height: calc(21/14);
	img {
		width: 24px;
		height: 24px;
		display: block;
		border-radius: 50%;
	}
	@include respond($bp-padding) {

	}
}

.now-playing-hd2 {
	flex: 0 0 100%;
	border-radius: 4px;
	box-shadow: 0 0 40px rgba(0,0,0,.05);
	padding: 24px;
	background: #fff;
	.now-playing-hd2-title {
		margin: 0;
	}
	p {
		margin: 8px 0;
		font-size: 14px;
	}
	.showTabs-box-link {
		box-shadow: none;
		margin-top: 16px;
	}
	.showTabs-box-live {
		white-space: nowrap;		
		font-size: 1.2rem;
		line-height: 1;
		text-transform: uppercase;
		font-weight: 700;
		background: #007fad;
		margin: 0 0 4px 0;
		text-align: center;
		color: #fff;
		padding: 6px 8px 4px;
		border-radius: 4px;		
	}
	.showTabs-box-image-inner {
		position: relative;
		.play-pause-button {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
}



.listenTop-right {
	max-width: 100%;
	overflow: hidden;
	.section-title-wrap {
		margin: 0 0 32px 0;
	}
}

.showTabs {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 32px;
}

.showTabs-btn {
	flex: 1 1 33.3333%;
	background: none;
	padding: 0 8px 16px 8px;
	border-bottom: 3px solid transparent;
	color: $color-dark;
	font-weight: bold;
	font-size: 1.6rem;
	line-height: calc(26/16);
	@include respond($bp-padding) {
		font-size: 1.8rem;
		line-height: calc(32/18);
	}
	&.is-active {
		border-color: $color-red;
	}
}

.showTabs-panels {
	padding: 48px 0 0 0;
}

.showTabs-panel {
	display: none;
	&.is-active {
		display: block;
	}
}

.showTab-swiper {
	height: 496px !important;
	padding-top: 16px !important;
	margin-top: -16px !important;
	@include respond(1200px) {
		height: 240px !important;

	}
}

.showTabs-box-link {
	text-decoration: none;
	color: $color-body;
	box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
	border-radius: 4px;
	background: #fff;
	display: flex;
	align-items: stretch;
	justify-content: flex-start;
	gap: 16px;
	//overflow: hidden;
}
.showTab-swiper-1 {
	width: 100%;
	height: 100%;
	margin-left: auto;
	margin-right: auto;
}

.showTabs-box {
	height: calc((100% - 96px) / 4) !important;
	@include respond(1200px) {
		height: calc((100% - 32px) / 2) !important;
	}
	&.is-active {
		.showTabs-box-image {
			position: relative;
			&:before {
				content:"Live Now";
				white-space: nowrap;
				font-family: $font;
				font-size: 1.2rem;
				line-height: 1;
				text-transform: uppercase;
				font-weight: $bold;
				background: $color-teal;
				color: #fff;
				padding: 6px 8px 4px 8px;
				border-radius: 4px;
				position: absolute;
				left: 50%;
				bottom: 100%;
				transform: translate(-50%, 50%);
			}
		}
	}
}

.showTabs-box-image {
	flex: 0 0 96px;
	.showTabs-box-image-inner {
		padding-top: 100%;
		background-color: $color-blue;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
	}
}

.showTabs-box-text {
	padding: 8px 8px 8px 0;
	display: flex;
	flex-direction: column;
	align-content: flex-start;
	justify-content: center;
}

.showTabs-box-person {
	font-weight: $bold;
	font-size: 1.2rem;
	line-height: calc(18/12);
	text-transform: uppercase;
}

.showTabs-box-title {
	font-size: 1.4rem;
	line-height: calc(21/14);
}

.showTabs-box-time {
	font-size: 1.2rem;
	line-height: calc(18/12);
	span:after {
		content: " | ";
	}
	span:last-of-type:after {
		content: "";
	}
}

.showTabs-panel {
	.showTabs-pagination{
		position: static;
		width: auto !important;
		.swiper-pagination-bullet-active {
			background: #808285;
		}
	}
}

.showTabs-nav {
	margin: 40px 0 0 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 16px;
	* {
		flex-shrink: 0;
	}
}

.swiper-arrows {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 48px;
	button {
		background: none;
		padding: 0;
		display: inline-flex;
		align-items: center;
		justify-content: flex-start;
		font-size: 1.6rem;
		line-height: calc(24/16);
		gap: 8px;
		color: $color-red;
		font-weight: $bold;
		text-decoration: none;
		white-space: nowrap;
		@include respond(600px) {
			font-size: 1.8rem;
			line-height: calc(32/18);
		}
		svg {
			flex: 0 0 24px;
			transition: all 0.4s ease;
			position: relative;
			top: -1px;
		}
		&.swiper-back svg {
			transform: rotate(180deg);
		}
		&:hover {
			text-decoration: none;
			color: darken($color-red, 10%);
			svg {
				transform: translateX(4px);
			}
			&.swiper-back svg {
				transform: translateX(4px) rotate(180deg);
			}
		}

		&.swiper-button-disabled {
			pointer-events: none;
			color: #B3B4B6;
			svg * {
				fill: #B3B4B6;
			}
		}

	}
}
