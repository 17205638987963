.featured {
	padding: 0 0 128px 0;
}

.featured .section-title-wrap {
	justify-content: center;
}

.featured-grid {
	display: grid;
	grid-template-columns: 1fr;
	gap: 24px;
	@include respond(560px) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
		gap: 32px;
	}
	@include respond(1200px) {
		grid-template-columns: repeat(4, minmax(0, 1fr));	
	}
}

.featured-grid-box {
	padding: 40px 24px;
	background: #FFFFFF;	
	box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
	border-radius: 4px;
	text-align: center;
	svg {
		display: block;
		margin: 0 auto 8px auto;
		@include respond($bp-padding) {
			margin: 0 auto 16px auto;
		}
	}
}

.featured-grid-box-title {
	font-weight: $medium;
	font-size: 2rem;
	line-height: calc(34/20);
	margin: 0 0 8px 0;
	color: $color-dark;
	@include respond($bp-padding) {
		font-size: 2.4rem;
		line-height: calc(40/24);	
		margin: 0 0 32px 0;
	}
}

.featured-grid-box .btn {
	width: 100%;
	text-align: center;
}