.openings {
	background-image: url(../webp/bottom-right-blur.webp);
	background-size: auto;
	background-repeat: no-repeat;
	background-position: right bottom;
	padding: 0 0 64px 0;
	@include respond($bp-padding) {
		padding: 0 0 120px 0;	
	}	
	.section-title-wrap {
		justify-content: center;
		.section-title {
			margin-bottom: 32px;
			@include respond($bp-padding) {
				margin-bottom: 64px;
			}
		}
	}	
	
	.container {
		max-width: 1138px;
	}
}


.openings-block {
	padding: 24px;
	background: #FFFFFF;	
	box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
	border-radius: 4px;
	margin: 0 0 64px 0;
	@include respond(768px) {
		padding: 32px;
	}
	@include respond($bp-padding) {
		padding: 64px;
	}
	
	&:last-of-type {
		margin: 0;
	}
}

.openings-block-title {
	font-weight: $medium;
	font-size: 2.4rem;
	line-height: 1;
	margin: 0 0 16px 0;
	@include respond($bp-padding, "down") {
		font-size: 2.2rem;
		line-height: 24px;
	}
}

.openings-block-time {
	display: inline-flex;
	align-items: center;
	padding: 4px 8px;
	gap: 8px;	
	background: #FFFFFF;	
	border: 1px solid rgba(128, 130, 133, 0.05);	
	box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
	border-radius: 4px;
	font-weight: $bold;
	font-size: 1.4rem;
	line-height: 21px;
	color: #808285;
	margin: 0 0 16px 0;
}

.openings-block-accordion {
	display: inline-flex;
	align-items: center;
	gap: 8px;
	font-weight: $bold;
	color: $color-red;
	font-size: 1.6rem;
	line-height: 26px;
	background: transparent;
	padding: 0;
	
	@include respond($bp-padding, "down") {
		font-size: 1.4rem;
		line-height: 21px;	
	}
	svg * {
		fill: $color-red;
	}
	
	&.is-expanded svg {
		transform: rotate(180deg);
	}
}

.openings-block p {
	font-size: 1.8rem;
	line-height: 32px;
	margin: 0 0 16px 0;
	color: $color-dark;
	@include respond($bp-padding, "down") {
		font-size: 1.6rem;
		line-height: 26px;	
	}
}

.openings-block-hidden {
	display: none;
}

.openings-block-foot {
	margin: 24px 0 0 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 32px;
	flex-wrap: wrap;
}

.openings-block-social {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex: 0 0 auto;
	gap: 16px;
	a {
		display: block;
		svg {
			display: block;
		}
		&:hover svg * {
			fill: $color-blue;
		}
	}
}