// -----------------------------------------------------------------------------
//! popup Styling
// -----------------------------------------------------------------------------

.popup-overlay {
	display: none;
	position: fixed;
	top:0;
	bottom:0;
	left:0;
	right:0;
	height:100%;
	background: rgba(0,0,0, 0.25);
	z-index: $z_index-level6 + 11;
	opacity: 0;	
}

.popup-wrapper {
	position: fixed;
	padding:10px 10px 0 10px;
	top:0;
	right:0;
	bottom:0;
	left:0;
	height:100%;
	z-index: $z_index-level6 + 12;
	overflow: auto;
	display: none;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	opacity: 0;
	&.is-active {
		display: flex;
	}
}

.popup {
	position: relative;
	margin-bottom:10px;
	width:100%;	
	max-height:100%;
	display: block;
	align-items: center;
	justify-content: center;
}   


body.has-popup {
	overflow: hidden;	
	.popup-overlay {
		display: block;
	}
}


// -----------------------------------------------------------------------------
//! popup Innards
// -----------------------------------------------------------------------------

.popup-inner-wrap {
	padding: 0;
	overflow: hidden;
	background:#fff;
	border-radius: 12px;
	position: relative;
	max-width: 360px;
	margin: 0 auto;
	top:-200px;
	
	
}

.popup-action {
	padding: 24px 16px;
	
	a {
		font-family: $font;
		text-decoration: none;
		padding: 16px 32px;
		border-radius: 4px;
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		line-height: 24px; /* 150% */
		letter-spacing: -0.32px;
		color: #fff;
		display: block;
		width: 100%;
		background: $color-teal;
		text-align: center;
		
	}
}


.popup-content {
	width:100%;
	img {
		width:100%;
		height:auto;
	}
}

.popup-content-window {
	height: 300px;
	overflow: auto;
	margin: 32px 0 0 0;
	p {
		font-size: 1.6rem;
		line-height: calc(26/16);
	}	
}

.popup-subtitle {
	font-weight: $bold;
	font-size: 1.8rem;
	line-height: calc(32/18);
	margin: 32px 0 16px 0;
	color: $color-body;
	font-family: $font;
}


// -----------------------------------------------------------------------------
//! popup Close
// -----------------------------------------------------------------------------


.popup-close {
	border:none;
	background: transparent;
	position: absolute;
	height:42px;
	width:42px;
	top:16px;
	right:16px;
	z-index: 2;
	cursor: pointer;
	background-image: url(../svg/modal-close-blue.svg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;	
}

.popup-close:focus {
	outline: none;
}





