$bp-contact-stack: 660px;


	.freeform-label,
	.hs-form-field label {
		color: $color-body;
		font-size: 1.6rem;
		line-height: calc(26/16);
		font-weight: bold;
		display: block;
		margin: 0 0 8px 0;
	}
	
	.freeform-row .freeform-column .freeform-label.freeform-required::after {
		content: "*";
		color: $color-body;
	}
	
	input.freeform-input,
	.hs-form-field .input input {
		height: 48px;
		line-height: 48px;
		border-radius: 4px;
		background: #fff;
		padding: 0 16px;
		font-size: 1.6rem;
		font-family: $font;
		border: 1px solid #808285;
		&:focus {
			border-color: $color-teal;
		}
	}
	select {
		height: 48px;
		width: 100%;
		line-height: 48px;
		border-radius: 4px;
		background: #fff;
		padding: 0 16px;
		font-size: 1.6rem;
		font-family: $font;
		border: 1px solid #808285;
		&:focus {
			border-color: $color-teal;
		}
	}
	
	textarea {
		border-radius: 4px;
		line-height: calc(26/16);
		background: #fff;
		padding: 16px;
		font-size: 1.6rem;
		font-family: $font;
		border: 1px solid #808285;
		&:focus {
			border-color: $color-teal;
		}
	}
	
	.freeform-row button,
	.freeform-button-wrapper button,
	.hs-button.primary.large {
		@extend .btn;
		font-size: 2rem;
		line-height: calc(24/20);
		width: 100%;
		padding: 16px;
	}
	
	.page-events .freeform-row button {
		@extend .btn-lg;
		width: auto;
	}
	
	.freeform-row .freeform-column {
		padding-top: 0;
		padding-bottom: 0;
		position: relative;
		float: none;
	}
	
	.ff-form-errors {
		display: none !important;
	}
	
	.ff-errors {
		width: auto; 
		position: absolute;
		right: 15px;
		top: 0px;
		background: $color-blue;
		border-radius: 4px;
		padding: 8px 16px !important;
		margin: 0 !important;
		border: 1px solid #fff;
		z-index: $z_index-level3;
		li {
			padding: 0;
			margin: 0;
			color: #fff !important;
			font-size: 1.4rem;
			line-height: 1.5; 
			list-style: none;
		} 
		&:after {
			content: "";
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 10px 7px 0 7px;
			border-color: $color-blue transparent transparent transparent;
			position: absolute;
			right: 8px;
			top: 100%;
			z-index: $z_index-level1 + 1;
		}
	}
	
	.ff-has-errors {
		position: relative;
		color: $color-red;
		border-color: $color-red !important;
		&::placeholder {
			opacity: 1;
		}
	}
	
	.ff-form-success {
		grid-column: 1 / span 2;
		text-align: center;
		grid-row: 5;
		@include respond($bp-contact-stack, "down") {
			grid-column: 1;
		}
		
		p {
			font-size: 1.8rem;
			line-height: calc(32/18);
			font-weight: $bold;
			color: $color-teal;
		}
	}

.ff-fieldtype-checkbox_group,
.ff-fieldtype-checkbox {
	
}

.freeform-subtitle {
	color: $color-blue;
	font-weight: $medium;
	font-size: 1.6rem;
	line-height: calc(24/16);	
	letter-spacing: -0.02em;
	margin: 24px 0 8px 0 !important;
	border-bottom: 1px solid $color-border;
	padding-bottom: 10px;
	@include respond($bp-padding) {
		font-size: 2rem;
		line-height: calc(32/20);
	}
}


.ff-fieldtype-checkbox {
	input {
		position: absolute;
		opacity: 0;
		left:-1px;
		top:-1px;
		height:1px;
		width:1px;
	}
	
	label {
		display: block;
		font-size: 1.8rem;
		font-weight: regular;
		padding-left: 32px;
		line-height: calc(32/18);
		cursor: pointer;
		position: relative;
	}
	
	label:before {
		content:"";
		position: absolute;
		left:0;
		top:2px;
		width:24px;
		height:24px;
		background-image: url(../svg/checkbox.svg);
		background-repeat: no-repeat;
		background-size: 24px 24px;
		background-color: transparent;
	}
	
	 input:checked + label:before {
		background-image: url(../svg/checkbox-checked.svg);
	}
}

.form-check {
	input {
		position: absolute;
		opacity: 0;
		left:-1px;
		top:-1px;
		height:1px;
		width:1px;
	}
	
	label {
		display: block;
		font-size: 1.8rem;
		font-weight: regular;
		padding-left: 32px;
		line-height: calc(32/18);
		cursor: pointer;
		position: relative;
	}
	
	label:before {
		content:"";
		position: absolute;
		left:0;
		top:2px;
		width:24px;
		height:24px;
		background-image: url(../svg/checkbox.svg);
		background-repeat: no-repeat;
		background-size: 24px 24px;
		background-color: transparent;
	}
	
	 input:checked + label:before {
		background-image: url(../svg/checkbox-checked.svg);
	}
		
}

.form-radio {
	input {
		position: absolute;
		opacity: 0;
		left:-1px;
		top:-1px;
		height:1px;
		width:1px;
	}
	
	label {
		display: block;
		font-size: 1.6rem;
		font-weight: regular;
		padding-left: 28px;
		line-height: calc(26/16);
		cursor: pointer;
		position: relative;
	}
	
	label:before {
		content:"";
		position: absolute;
		left:0;
		top:2px;
		width:20px;
		height:20px;
		border-radius: 50%;
		border: 2px solid #293446;
		
	}
	
	 input:checked + label:after {
		content: "";
		width: 8px;
		height: 8px;
		position: absolute;
		background: $color-teal;
		border-radius: 50%;
		left: 6px;
		top: 8px;
	}
		
}

.using-keyboard .form-check input:not(:checked):focus + label:before {
	background-image: url(../svg/checkbox-focus.svg);
}

.using-keyboard .form-radio input:not(:checked):focus + label:before {
	border-color: $color-teal;
}

.checkbox-grid > label {
	display: none;
}

.checkbox-grid {
	display: grid;
	gap: 24px 16px;
	grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
}

.radio-grid {
	display: flex;
	gap: 16px 30px;
	flex-wrap: wrap;
	.form-radio {
		flex: 0 0 auto;
	}
}

.freeform-column-content-align-center {
	text-align: center;
}

.form-tc {
	padding: 24px 0 0 0;
	text-align: center;
	button {
		cursor: pointer;
		background: none;
		border: none;
		padding: 0;
		color: $color-blue;
		font-size: 1.6rem;
		line-height: calc(24/16);
		font-weight: $bold;
		text-decoration: none;
		display: inline-block;
		&:hover {
			text-decoration: underline;
		}
		@include respond($bp-padding) {
			font-size: 2rem;
			line-height: calc(32/20);
		}
	}
}


.freeform-aside {
	background: rgba(128, 130, 133, 0.05);
	border-radius: 4px;
	padding: 32px 24px;	
	display: flex;
	align-items: flex-start;
	justify-content: center;
	gap: 16px;
	margin: 32px 0;
	p {
		font-size: 1.4rem;
		line-height: 1.5;
		font-weight: bold;
		margin: 0;
	}
	
	.freeform-aside-icon {
		width: 24px;
		height: 24px;
		flex: 0 0 24px;
		background-image: url(../svg/icon-info.svg);
		background-size: 24px 24px;
		background-position: center center;
		background-repeat: no-repeat;
	}
}

.remaining {
	text-align: right;
	margin: 8px 0 0 0;
	font-size: 1.4rem;
	line-height: calc(21/14);
}


.freeform-row .freeform-submit-column.freeform-column.freeform-column-content-align-left {
	display: flex !important;
	align-items: center;
	justify-content: flex-end;
	gap: 24px;
	button[type="submit"]{
		width: 116px;
		flex: 0 0 116px;
		text-align: center;
	}
	
	.modal-cancel {
		flex: 0 0 auto;
		width: auto;
		padding: 0;
		font-size: 18px;
		line-height: 32px;
	}
}

.freeform-file-dnd {
	margin-top: 8px;
}


.page-jobs {
	
	.ff-form-success {
		max-width: 610px;
		margin: 0 auto;
		&:before{
			content:"Your application has been sent.";
			font-family: $font-accent;
 			font-weight: $medium;
			font-size: 2.8rem;
			line-height: 40px;
			text-align: center;
			letter-spacing: -0.02em;
			color: $color-blue;
			margin: 0 0 12px 0;
		}
	}
	
}

.openings-block-modal-subtitle {
	margin-top: 0;
	font-weight: 700;
}


.hide-check {
	display: none;
}

.freeform-errors li {
	color: $color-red;
	list-style: none;
}


.partnerForm .freeform-button-wrapper {
	width: 100% !important;
	flex: 0 0 100%;
	button {
		width: 100%;
	}
}