.prayer {
	padding: 64px 0;
	background-color: $color-blue;
	background-image: url(../webp/prayer-center.webp);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	@include respond($bp-padding) {
		padding: 120px 0;
	}
	* {
		color: #fff;
	}
}

.prayer .container {
	max-width: 1138px;
}

.prayer .section-title-wrap {
	display: block;
	text-align: center;
	.section-title {
		margin: 0 0 32px 0;
	}
}

.prayer-subtitle {
	text-align: center;
	margin: 0 auto 32px auto;
	max-width: 500px;
	@include respond($bp-padding) {
		font-size: 2rem;
		line-height: calc(32/20);
	}
}

.prayer-title-wrap {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
	gap: 8px 24px;
	margin: 0 0 24px 0;
	@include respond($bp-padding) {
		margin: 0 0 64px 0;	
	}
	.view-all:hover {
		color: #fff;
	}
	.view-all svg * {
		fill: #fff;
	}
}

.prayer-title {
	font-weight: $medium;
	margin: 0 auto 0 0;
	font-size: 1.8rem;
	line-height: calc(24/18);
	@include respond($bp-padding) {
		font-size: 3.2rem;
		line-height: calc(48/32);
	}
}

.prayer-box {
	background: rgba(255, 255, 255, 0.2);	
	box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
	border-radius: 4px;
	padding: 32px;
	@include respond($bp-padding) {
		padding: 64px;
	}
}

.prayer-meta {
	margin: 32px 0 0 0;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 24px;
}

.prayer-box p {
	letter-spacing: -0.02em;
	font-size: 1.8rem;
	line-height: calc(26/18);
	margin: 0;
	@include respond($bp-padding) {
		font-size: 2.6rem;
		line-height: calc(32/26);
	}
}

.prayer-name {
	font-weight: $bold;
	font-size: 1.8rem;
	line-height: calc(32/18);
}