.verse {
	background-color: rgba(128, 130, 133, 0.05);
	background-image: url(../svg/grad.svg);
	background-repeat: no-repeat;
	background-position: center top;
	background-size: auto;
	position: relative;
	padding: 64px 0;
	@include respond($bp-padding) {
		padding: 120px 0;
	}
	
	.container {
		max-width: 1098px;
	}	
}


.verse-box {
	background: #FFFFFF;
	box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
	border-radius: 4px;
	padding: 32px 24px;
	text-align: center;
	@include respond($bp-padding) {
		padding: 96px;
	}	
	
	.section-title {
		margin-bottom: 32px;
		@include respond($bp-padding) {
			margin-bottom: 64px;
		}
	}
}


.verse-box-pretitle {
	font-weight: $bold;
	font-size: 1.6rem;
	line-height: calc(26/16);
	margin: 0 0 8px 0;
	@include respond($bp-padding) {
		font-size: 1.8rem;
		line-height: calc(32/18);
	}
}

.verse-content {
	color: #0D1821;
	letter-spacing: -0.02em;
	font-size: 1.8rem;
	line-height: calc(26/18);
	margin: 0 0 24px 0;
	text-align: left;
	@include respond($bp-padding) {
		font-size: 2.6rem;
		line-height: calc(32/26);
		margin: 0 0 32px 0;
	}
}

.verse-cite {
	font-weight: $bold;
	color: #0D1821;
	font-size: 1.4rem;
	line-height: calc(21/14);
	text-align: left;
	@include respond($bp-padding) {
		font-size: 1.8rem;
		line-height: calc(32/18);
	}
}

.verse-share {
	margin: 24px 0 0 0;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 32px;
	a {
		display: block;
		svg {
			display: block;
		}
		&:hover svg * {
			fill: darken($color-red, 10%);
		}
	}
}

.verse-sponsor {
	
	margin: 32px 0 0 0;
	text-align: center;
	@include respond($bp-padding) {
		margin: 64px 0 0 0;
	}
	a {
		opacity: 0.5;
		&:hover {
			opacity: 0.75;
		}
	}
	img {
		display: inline-block;
	}
}