.contactBlocks {
	padding: 0px 0 64px 0;

	@include respond($bp-contentStack) {
		padding: 64px 0;
	}

	@include respond($bp-padding) {
		padding: 120px 0;
	}

	@include respond($bp-contentStack) {
		background: radial-gradient(40% 100% at 0% 0%, rgba(0, 127, 173, 0.3) 0%, rgba(0, 127, 173, 0) 100%);
	}
}

.contactBlocks-grid {
	display: grid;
	gap: 32px;
	grid-template-columns: 1fr;
	margin: 0 0 64px 0;

	@include respond(620px) {
		grid-template-columns: 1fr 1fr;
	}

	@include respond($bp-contentStack) {
		grid-template-columns: repeat(3, minmax(0, 1fr));
		margin: 0 0 96px;
	}
}

.contactBlocks-block {
	display: flex;
	padding: 32px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: #fff;
	border-radius: 4px;
	box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
	text-decoration: none;
	text-align: center;
	gap: 32px;
	pointer-events: none;

	@include respond($bp-contentStack, "down") {
		gap: 16px;
		flex-direction: row;
		padding: 24px;
		pointer-events: auto;
		justify-content: flex-start;
	}
}

.contactBlocks-block svg {
	display: block;
	width: 80px;
	height: 81px;

	@include respond($bp-contentStack, "down") {
		width: 48px;
		height: 48px;
	}
}


.contactBlocks-text {
	@include respond($bp-contentStack, "down") {
		text-align: left;
	}
}

.contactBlocks-title {
	font-family: $font-accent;
	font-weight: $medium;
	font-size: 1.8rem;
	line-height: calc(24/18);
	color: $color-blue;

	@include respond($bp-padding) {
		font-size: 2.8rem;
		line-height: calc(40/28);
		letter-spacing: -0.02em;
		max-width: 260px;
	}
}

.contactBlocks-phone {
	color: $color-body;
	font-weight: $bold;
	font-size: 1.6rem;
	line-height: 26px;

	@include respond($bp-padding) {
		font-size: 2rem;
		line-height: calc(32/20);
	}
}

.contactBlocks-mail {
	text-align: left;

	@include respond($bp-contentStack) {
		text-align: center;
	}
}

.contactBlocks-mail-title {
	font-weight: $medium;
	font-family: $font-accent;
	color: $color-blue;
	margin: 0 auto 16px auto;
	font-size: 1.6rem;
	line-height: calc(26/16);

	@include respond($bp-padding) {
		max-width: 530px;
		font-size: 2rem;
		line-height: calc(32/20);
		letter-spacing: -0.02em;
		margin: 0 auto 32px auto;

	}
}

.contactBlocks-mail-text {
	font-weight: $bold;
	font-size: 1.6rem;
	line-height: calc(26/16);

	@include respond($bp-padding) {
		font-weight: $regular;
		font-size: 1.8rem;
		line-height: calc(32/18);
	}
}