.gift {
	background: rgba(128, 130, 133, 0.05);
	padding: 64px 0;
	@include respond($bp-padding) {
		padding: 120px 0;
	}
}

.gift-box {
	background: #FFFFFF;
	box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
	border-radius: 4px;
	padding: 32px 24px;
	text-align: center;
	max-width: 1040px;
	margin: 0 auto;
	@include respond($bp-padding) {
		padding: 96px;
	}	
	
	.section-title {
		margin: 16px 0 32px 0;
		@include respond($bp-padding) {
			margin: 32px 0;
		}
	}
	p {
		max-width: 644px;
		margin: 0 auto 32px auto;
		@include respond($bp-padding) {
			font-size: 2rem;
			line-height: calc(32/20);
		}
	}
	
	svg {
		@include respond($bp-padding, "down") {
			width: 72px;
			height: 72px;
		}
	}
}