

.content {
	padding: 22px 0 0 0;
	@include respond($bp-padding) {
		padding: 120px 0 0 0;	
	}
	
}


.contentBlock {
	margin: 64px 0;
	@include respond($bp-padding) {
		margin: 120px 0;
	}
	
	&:last-of-type {
		margin-bottom: 0;
	}
}

.modular-content {
	.contentBlock:first-of-type {
		margin-top: 0;
	}	
}


.image-center {
	text-align: center;
}


// -----------------------------------------------------------------------------
//! Image/Text
// -----------------------------------------------------------------------------

.content-imageBlock {
	.container {
		display: grid;
		grid-template-columns: 1fr;
		gap: 32px;
		@include respond($bp-contentStack) {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;	
		}
	}
	
	.section-title {
		margin: 0 0 32px 0;
	}
	
	p {
		font-size: 1.4rem;
		line-height: calc(21/14);
		@include respond($bp-padding) {
			font-size: 1.6rem;
			line-height: calc(26/16);	
		}
	}
	
	.imageBlock-text {
		flex: 0 1 640px;		
	}
	
	.imageBlock-image {
		flex: 0 1 640px;		
	}
}

// -----------------------------------------------------------------------------
//! Restrained Text
// -----------------------------------------------------------------------------

.content-restrainedText {
	.container {
		max-width: 640px;
	}	
	
	margin: 0 0 24px 0;
	@include respond($bp-padding) {
		margin: 0 0 64px 0;	
	}
	
	h2 {
		font-family: $font-accent;
		font-style: normal;
		font-weight: $medium;
		font-size: 2rem;
		line-height: 32px;
	}
	
}

.page-jobs .content-restrainedText {
	padding: 0 0 64px 0;
	.container {
		max-width: none;
	}	
	@include respond(860px) {
		padding: 120px 0;	
		background: radial-gradient(40% 100% at 0% 0%, rgba(0, 127, 173, 0.3) 0%, rgba(0, 127, 173, 0) 100%);
		.container {
			max-width: 640px;
		}	
	}	
}



// -----------------------------------------------------------------------------
//! Cards
// -----------------------------------------------------------------------------

.content-cards {
	.section-title {
		text-align: center;
		margin: 0 0 32px 0;
		padding: 0 24px;
	}
	.container {
		display: flex;
		align-items: stretch;
		flex-wrap: wrap;
		justify-content: center;
				
		gap: 32px;
		max-width: 1356px;
	}
	
	.errorGrid-box img, svg {
		max-width: 80px;
		max-height: 80px;
		width: auto;
		height: auto;
	}
}

// -----------------------------------------------------------------------------
//! Image with Text on Top
// -----------------------------------------------------------------------------

.content-imageWithTextOnTop {
	margin: 0;
	position: relative;
	padding: 64px 0;
	@include respond($bp-padding) {
		padding: 120px 0;
	}
	
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background: $color-blue;	
		z-index: 1;	
	}
	
	.content-imageWithTextOnTop-overlay {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0; 
		position: absolute;
		z-index: 2;
		opacity: 0.3;
	}
	
	.container {
		text-align: center;
		position: relative;
		z-index: 3;
		max-width: 908px;
		
		.section-title {
			color:#fff;
			margin: 0 0 32px 0;
		}
		p {
			color: #fff;
			margin: 0 0 32px 0;
			@include respond($bp-padding) {
				font-size: 20px;
				line-height: 32px;
				margin: 0 0 64px 0;
			}
		}
	}
}

// -----------------------------------------------------------------------------
//! Body Copy
// -----------------------------------------------------------------------------

.content-bodyCopy {
	padding: 64px 0 ;
	@include respond($bp-padding) {
		padding: 120px 0;
	}
	
	.container {
		max-width: 688px;
		h2.section-title {
			font-family: $font-accent;
			text-align: center;
			font-weight: $medium;
		}
		
		figure {
			&.image-center {
				margin: 2em auto;
			}
			&.image-left {
				margin: 2em auto;
				@include respond(600px) {
					float: left;
					max-width: 50%;
					margin: 1em 2em 2em 0;
				}
			}
			&.image-right {
				margin: 2em auto;
				@include respond(600px) {
					float: right;
					max-width: 50%;
					margin: 1em 0 2em 2em;
				}
			}
		}
	}
	&.content-bodyCopy-split .container {
		max-width: 1416px;
		display: flex;
		align-items: stretch;
		justify-content: space-between;
		gap: 40px;
		flex-wrap: wrap;
		h2.section-title {
			flex: 0 0 100%;
		}
		
		.copyContent-left,
		.copyContent-right {
			@include respond(688px) {
											
				flex: 1 1 calc(50% - 20px);
				min-width: 500px;
			}
		}
	}
	
	
	&.content-bodyCopy-three .container {
		max-width: 1416px;
		display: flex;
		align-items: stretch;
		justify-content: space-between;
		gap: 33px;
		flex-wrap: wrap;
		h2.section-title {
			flex: 0 0 100%;
		}
		.copyContent {
			@include respond(900px) {
				flex: 1 1 calc(33.3333333333333333333333% - 22px);
				min-width: 300px;
			}
		}
	}
	&.bodyCopy-white {
		padding-top: 0;
		padding-bottom: 0;
	}
	&.bodyCopy-blue {
		margin: 0;
		background-color: $color-blue;
		h1,h2,h3,h4,h5,h6,p,blockquote,span, a {
			color: #fff;
		}
		a {
			text-decoration: underline;
		}
	}
	
}

// -----------------------------------------------------------------------------
//! AltBlocks
// -----------------------------------------------------------------------------


.content-alternatingImageText {
	// padding: 64px 0 ;
	// @include respond($bp-padding) {
	// 	padding: 120px 0;
	// }
	
	.altBlock-row {
		margin-top:40px;
		display: grid;
		grid-template-columns: 1fr;
		gap: 32px;
		&:first-child {
			margin: 0;
		}
		@include respond($bp-contentStack) {
			margin-top:80px;
			display: flex;
			align-items: flex-start;
			justify-content: space-between;	
			&:first-child {
				margin: 0;
			}
		}
	}
	
	
	.section-title {
		margin: 0 0 32px 0;
	}
	
	p {
		font-size: 1.4rem;
		line-height: calc(21/14);
		@include respond($bp-padding) {
			font-size: 1.6rem;
			line-height: calc(26/16);	
		}
	}
	
	.imageBlock-text {
		flex: 0 1 640px;		
	}
	
	.imageBlock-image {
		flex: 0 1 640px;		
	}
	
	@include respond($bp-contentStack) {
		
		.altBlock-row:nth-of-type(even) {
			.imageBlock-text {
				order: 2;
			}
		}
		
	}
	
}


// -----------------------------------------------------------------------------
//! Images
// -----------------------------------------------------------------------------

.content-images {
	margin: 0;
	padding: 64px 0;
	background: rgba(128, 130, 133, 0.05);
	@include respond($bp-padding) {
		padding: 120px 0;
	}
	
	.section-title {
		text-align: center;
		margin: 0 0 32px 0;
	}
	.images-grid {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
		gap: 32px;
	}
	
	.images-grid-caption {
		font-size: 1.6rem;
		line-height: 26px;
		padding: 12px 0 0 0;
	}
	a {
		display: block;
	}
	img {
		border-radius: 4px;
		display: block;
	}
	
}

// -----------------------------------------------------------------------------
//! Video
// -----------------------------------------------------------------------------

.content-video {
	margin: 0;
	padding: 64px 0;
	background: rgba(128, 130, 133, 0.05);
	text-align: center;
	@include respond($bp-padding) {
		padding: 120px 0;
	}	
	.container {
		max-width: 700px;
	}
	.section-title {
		margin: 0 0 32px 0;
	}
	.embedcontainer {
		position: relative; 
		padding-bottom: 56.25%; 
		height: 0; 
		overflow: hidden; 
		max-width: 100%; 
	}	
	
	.embedcontainer {
		iframe,
		embed,
		object {
			position: absolute; 
			top: 0; 
			left: 0; 
			width: 100%; 
			height: 100%; 	
		}
		
	}
}

// -----------------------------------------------------------------------------
//! Form
// -----------------------------------------------------------------------------


.content-form.donateForm {
	
	.section-title {
		text-align: center;
		margin: 0 0 32px 0;
	}
	&.form-white {
		padding: 0;
		background: #fff;	
		form {
			background: transparent;
			padding: 0;
			box-shadow: none;
		}
	}
	&.form-grey {
		margin: 0;
		background: rgba(128, 130, 133, 0.05);
		padding: 64px 0;
		@include respond($bp-padding) {
			padding: 120px 0;
		}
	}
	
}