// -----------------------------------------------------------------------------
//! EASY MEDIA QUERIES
// -----------------------------------------------------------------------------

	@mixin respond($breakpoint, $operator: "up", $dimension: 'width') {
		
		@if $operator == "up"
		{
			@media print, screen and ( min-#{ $dimension }: $breakpoint) { 
				@content; 
			}
		} @else if $operator == "down" {
			@if unit( $breakpoint ) == "px" {
				$breakpoint: $breakpoint - 1;
			}
			@media screen and (max-#{ $dimension }: $breakpoint) { 
				@content; 
			}
		} @else  {
			@if unit( $operator ) == "px" {
				$operator: $operator - 1;
			}
			@media screen and (min-#{ $dimension }: $breakpoint) and (max-#{ $dimension }: $operator) {
				@content; 
			}
		}
		
	}

	