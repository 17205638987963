.page-search .pageTitle {
	padding: 138px 0 82px 0;
	@include respond(860px) {
		padding: 172px 0;
	}
	
	.pageTitle-spacer {
		@include respond(860px, "down") {
			position: absolute;	
			padding: 0;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			z-index: $z_index-level1;
		}
	}
	
	.hero-wrap {
		@include respond(860px, "down") {
			padding:0;
			background: none;	
		}
	}
}

.showSearch {
	padding: 0 0 0px 0;
	@include respond($bp-padding) {
		padding: 0 0 0 0;	
	}
}

.search-header {
	text-align: center;
	padding: 64px 0 24px 0;
	@include respond($bp-padding) {
		padding: 140px 0 64px 0;
	}
}

.searchGrid-entries {
	flex: 1 1 auto;
}

.searchGrid-entries-grid {
	display: grid;
	
	gap: 32px;
	width: 100%;
	margin: 0 0 40px 0;
	position: relative;
	z-index: $z_index-level2;
	grid-template: 1fr;
	@include respond($bp-padding) {
	grid-template-columns: repeat(2, minmax(0, 1fr));	
	}
}


.searchGrid-entry {
	display: flex;
	align-items: stretch;
	justify-content: flex-start;
	gap: 24px;
	background: #fff;
	box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
}

.searchGrid-entry-image {
	flex: 0 0 128px;
	background-size: 80px 80px;
	background-repeat: no-repeat;
	background-position: center center;
	min-height: 128px;
	background-image: url(../webp/KCBI_4color_mark.webp);
	
	&.type-resources {
		background-size: 80% auto !important;
	}
}

.searchGrid-entry-content {
	padding: 16px 16px 16px 0;
}

.searchGrid-entry-tag {
	display: inline-block;
	font-weight: 700;
	font-size: 14px;
	line-height: 21px;
	color: #fff;
	padding: 4px 8px;
	box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
	border-radius: 4px;
	margin: 0 0 8px 0;
	background: $color-teal;
}

.searchGrid-entry-title {
	display: block;
	text-decoration: none;
	font-size: 1.6rem;
	line-height: 26px;
	color: $color-body;
	@include respond($bp-padding) {
		font-size: 2rem;
		line-height: 32px;
	}
	&:hover {
		color: $color-teal;
		text-decoration: none;
	}
}

.pagination {
	position: relative;
	z-index: $z_index-level1 + 1;
}