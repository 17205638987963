.articleContent {
	.container {
		max-width: 688px;
	}
	figure {
		margin: 24px auto;
		img {
			border-radius: 4px;
		}
	}
	figcaption {
		font-size: 16px;
		line-height: 26px;
		color: $color-body;
		font-style: italic;
	}
	
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: $font;
		color: $color-dark;
		font-weight: $medium;
	}
	
	
	figure.image-align-left {
		float: left;
		max-width: 50%;
		margin: 24px 24px 24px 0;
		@include respond(640px, "down") {
			float: none;
			max-width: none;
			margin: 24px auto;
		}
	}
	
	figure.image-align-right {
		float: right;
		max-width: 50%;
		margin: 24px 0 24px 24px;
		@include respond(640px, "down") {
			float: none;
			max-width: none;
			margin: 24px auto;
		}
	}
	
	
	.fluid-width-video-wrapper iframe {
		width: 100% !important;
		height: 100% !important;
	}
}

.articleContent-share {
	padding: 64px 0;
	@include respond($bp-padding) {
		padding: 80px 0;
	}
	
	.container {
		padding: 40px;
		background: #fff;
		box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		gap: 24px 64px;
	}
	
	.articleContent-share-title {
		font-family: $font-accent;
		font-weight: $medium;
		font-size: 2rem;
		line-height: calc(32/20);
		letter-spacing: -0.02em;	
		color: $color-dark;
		margin: 0;
	}
	
	.articleContent-share-icons {
		display: flex;
		gap: 42px;
		justify-content: flex-start;
		align-items: center;
		a {
			flex: 0 0 28px;
			display: block;
			text-decoration: none;
			svg {
				width: 28px;
				height: 28px;
				
			}
		}
	}
}