.recent {

	padding: 0 0 64px 0;
	@include respond(600px) {
		padding: 80px 0;
	}

	@include respond(860px) {
		background: radial-gradient(40% 100% at 0% 0%, rgba(0, 127, 173, 0.3) 0%, rgba(0, 127, 173, 0) 100%);
	}
}

.swiper.swiper-recent {
	overflow: visible;
	.swiper-slide {
		width: auto;
	}

	.swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
		bottom: -43px;
	}

	.swiper-pagination-bullet-active {
		background: #808285;
	}
}

.recent-box {
	padding: 16px;
	background: #fff;
	box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
	border-radius: 4px;
	@include respond($bp-padding) {
		padding: 24px;
	}
}

.recent-box-album {
	border-radius: 4px;
	overflow: hidden;
	position: relative;
	display: inline-flex;
	margin: 0 0 12px 0;
	@include respond($bp-padding) {
		margin: 0 0 16px 0;
	}
	&:after {
		content:"";
		position: absolute;
		z-index: $z_index-level1 + 1;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		background: linear-gradient(360deg, rgba(0, 127, 173, 0.9) -16.15%, rgba(0, 127, 173, 0) 59.17%);
	}
	img {
		position: relative;
		z-index: $z_index-level1;
		display: block;
		max-width: 88px;
		@include respond($bp-padding) {
			max-width: 120px;
		}
	}
}

.recent-box-song {
	display: block;
	font-family: $font-accent;
	font-size: 1.6rem;
	line-height: calc(24/16);
	font-weight: $medium;
	letter-spacing: -0.02em;
	color: $color-teal;
	text-decoration: none;
	width: 183px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	@include respond($bp-padding) {
		font-size: 2rem;
		line-height: calc(32/20);
		width: 257px;
	}
}

.recent-box-meta {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 24px;
}

.recent-box-artist {
	color: $color-dark;
	font-weight: $bold;
	font-size: 1.4rem;
	line-height: calc(21/14);
	flex: 0 0 auto;
	@include respond($bp-padding) {
		font-size: 1.8rem;
		line-height: calc(32/18);
	}
}

.recent-box-time {
	font-size: 1.2rem;
	line-height: calc(21/12);
	color: $color-dark;
	@include respond($bp-padding) {
		font-size: 1.4rem;
		line-height: calc(32/14);
	}
}


.page-listen-live {
	.recent {
		background: #fff;
		padding-top: 0;
	}
}

// -----------------------------------------------------------------------------
//! Recently Played Page
// -----------------------------------------------------------------------------
.page-recently-played {

	.recentSongs {
		background-image: url(../webp/bottom-right-blur.webp);
		background-position: right bottom;
		background-size: auto;
		background-repeat: no-repeat;
		padding: 0 0 64px 0;
		@include respond($bp-padding) {
			padding: 120px 0;
		}

		.container {
			display: grid;
			grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
			gap: 24px;
			@include respond($bp-padding) {
				gap: 40px;
				grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
			}
		}

		.recent-box-itunes {
			display: inline-flex;
			align-items: center;
			justify-content: flex-start;
			gap: 8px;
			margin: 8px 0 0 0;
			text-decoration: none;
			svg * {
				fill: $color-teal;
			}
			&:hover {
				text-decoration: underline;

			}
		}
	}

}
