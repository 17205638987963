
button,
.btn {
	cursor: pointer;
	font-family: $font;
}

.btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-family: $font;
	font-weight: bold;
	border-radius: 4px;
	text-decoration: none;
	letter-spacing: -0.02em;
	font-size: 1.4rem;
	line-height: calc(20/14);
	padding: 7px 15px;
	position: relative;
	transition: all 0.4s ease 0.2s;
	border: 1px solid transparent;
	white-space: nowrap;
}

.btn-lg {
	padding: 16px 56px;
	font-size: 2.0rem;
	line-height: calc(24/20);	
}

.btn-sm {
	padding: 8px;
	font-weight: $bold;
	font-size: 14px;
	line-height: 20px;
}

.btn-md {
	padding: 16px 32px;
	font-size: 2.0rem;
	line-height: calc(24/20);	
}


.btn-red {
	background: $color-red;
	color: #fff;
	&:hover {
		background: darken($color-red, 10%);
	}
}

.btn-blue {
	background: $color-blue;
	color: #fff;
	&:hover {
		background: darken($color-blue, 10%);
	}
}

.btn-teal {
	background: $color-teal;
	color: #fff;
	&:hover {
		background: $color-blue;
	}
}

.btn-outline {
	background: transparent;
	border-color: $color-teal;
	color: $color-teal;
	&:hover {
		background: $color-teal;
		color: #fff;
	}
}


.btn-outline-white {
	background: transparent;
	border-color: #fff;
	color: #fff;
	&:hover {
		background: $color-teal;
		border-color: $color-teal;
		color: #fff;
	}
}


.view-all {
	display: inline-flex;
	align-items: center;
	justify-content: flex-start;
	font-size: 1.6rem;
	line-height: calc(24/16);
	gap: 8px;
	color: $color-red;
	font-weight: $bold;		
	text-decoration: none;
	white-space: nowrap;
	@include respond(600px) {
		font-size: 1.8rem;
		line-height: calc(32/18);
	}
	svg {
		flex: 0 0 24px;
		transition: all 0.4s ease;
	}
	&:hover {
		text-decoration: none;
		color: darken($color-red, 10%);
		svg {
			transform: translateX(4px);
		}
	}
}


.swiper-btn {
	background: transparent;
	padding: 0;
	display: inline-flex;
	align-items: center;
	justify-content: flex-start;
	font-size: 1.6rem;
	line-height: calc(24/16);
	gap: 8px;
	color: $color-red;
	font-weight: $bold;		
	text-decoration: none;
	white-space: nowrap;
	@include respond(600px) {
		font-size: 1.8rem;
		line-height: calc(32/18);
	}
	
	svg {
		flex: 0 0 24px;
		transition: all 0.4s ease;
	}
	
	&.swiper-button-back svg {
		transform: rotate(180deg);
	}
	
	&:hover {
		text-decoration: none;
		color: darken($color-red, 10%);
		svg {
			transform: translateX(4px);
		}
		&.swiper-button-back svg {
			transform: translate(-4px) rotate(180deg);
		}
	}
}
