.articleCategories {
	background-position: right bottom;
	background-size: auto auto;
	background-repeat: no-repeat;
	background-image: url(../webp/category-blur.webp);
	padding: 64px 0;
	@include respond($bp-padding) {
		padding: 120px 0;
	}
	
	.container {
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		gap: 16px;
		
		h2 {
			flex: 0 0 100%;
			color: $color-teal;
			margin: 0 0 8px 0;
			font-weight: $medium;
			font-size: 1.8rem;
			line-height: calc(24/18);
			@include respond($bp-padding) {
				font-size: 2.8rem;
				line-height: calc(40/28);
			}
		}
		
		span {
			display: block;
			font-weight: $bold;
			border-radius: 4px;
			letter-spacing: -0.02em;
			border: 1px solid $color-teal;
			padding: 8px 16px;
			line-height: calc(20/14);
			font-size: 1.4rem;
			color: $color-teal;
		}
	}
}