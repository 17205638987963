.partners {
	background-color: #fff;
	background-image: url(../svg/bottom-left-blur.svg);
	background-repeat: no-repeat;
	background-position: left bottom;
	background-size: auto;
	padding: 64px 0;
	@include respond($bp-padding) {
		padding: 120px 0;
	}
}

.partners .section-title-wrap {
	justify-content: center;
	max-width: 620px;
	text-align: center;
	margin: 0 auto 32px auto;
	@include respond($bp-padding) {
		margin: 0 auto 64px auto;	
	}
}

.partners-slider {
	
	a {
		display: block;
		img {
			display: block;
		}
	}
	
	img {
		border-radius: 4px;
	}
	
	.swiper-pagination {
		bottom: 0;
	}
	
	.swiper-partners {
		padding-bottom: 44px;
		@include respond($bp-padding) {
			padding-bottom: 76px;
		}
		&.is-static {
			.swiper-wrapper {
				flex-wrap: wrap;
				gap: 32px;
				@include respond(705px) {
					flex-wrap: nowrap;
					.swiper-slide {
						flex: 0 0 calc(50% - 16px);
					}
				}
			}
		}
	}
	
	.swiper-pagination-bullet {
		width: 12px;
		height: 12px;
		margin: 0 12px;
	}
	.swiper-pagination-bullet-active {
		background: #808285;
	}
	
}

.partners-slider-buttons {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 0 16px 0;
}



.partnerForm {
	padding: 64px 0;
	background-image: url(../webp/donate-bg.webp);
	background-repeat: no-repeat;
	background-size: auto;
	background-position: center bottom;
	
	.section-title {
		text-align: center;
		margin: 0 0 32px 0;
		@include respond($bp-padding) {
			margin: 0 0 64px 0;
		}
	}
	
	@include respond($bp-padding) {
		padding: 120px 0;
	}
	
	.container {
		//max-width: 688px;
	}
	
	form {
		box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
		background: #fff;
		padding: 24px;
		
		@include respond($bp-padding) {
			padding: 48px;
		}
	}
	
	.freeform-row {
		margin: 0;
		display: grid;
		gap: 8px;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		@include respond($bp-padding) {
			gap: 32px;	
		}
		
		
	}
	.freeform-row .freeform-column {
		margin: 32px 0 0 0;
		position: relative;
	}
	
	.freeform-row.freeform-row-1 .freeform-column {
		margin: 0;
	}
	
	.freeform-column-12 {
		grid-column: 1 / span 2;		
	}
	
	input.freeform-input {
		width: 100%;
		max-width: 100%;
	}
	
	textarea {
		width: 100%;
	}
	
	h3 {
		font-family: $font-accent;
		font-weight: $medium;
		font-size: 2rem;
		line-height: 32px;
		letter-spacing: -0.02em;		
		color: $color-blue;
		border-bottom: 1px solid $color-border;
		padding-bottom: 10px;
		margin: 0;
	}
	
	h4 {
		font-family: $font-accent;
		font-weight: $medium;
		font-size: 2rem;
		line-height: 32px;
		letter-spacing: -0.02em;		
		color: $color-blue;
		margin: 0;
		color: $color-body;
	}
	
	.radio-grid {
		gap: 24px;
		
		.form-radio {
			@include respond($bp-padding, "down") {
				flex: 0 0 100%;
				text-align: center;
			}
		}
		.form-button-label {
			padding: 16px 32px;
			line-height: 24px;
			font-size: 2rem;
			color: $color-teal;
			border:1px solid $color-teal;
			font-weight: $bold;
			border-radius: 4px;
			letter-spacing: -0.02em;
			transition: all 0.4s ease;
			&:before,
			&:after {
				display: none;
			}
		}
		
		.form-radio input:checked + label {
			background: $color-teal;
			color: #fff;
		}
	}
	
	input.freeform-input.other-amount {
		width: calc(100% - 24px);
		margin-left: 24px;
	}
	
	label.freeform-label.other-amount {
		position: absolute;
		left: 0;
		font-size: 16px;
		line-height: 26px;
		top: 50%;
		transform: translateY(-50%);
	}

}